import { LexicalEditor } from 'lexical';

import { SerializedCustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode/CustomNode';
import { IframeNode } from '@admin/molecules/RichEditor/Editor/plugins/IframePlugin';
import { ImageBlockNode } from '@admin/molecules/RichEditor/Editor/plugins/ImagePlugin';
import { JobsOverviewNode } from '@admin/molecules/RichEditor/Editor/plugins/JobsOverviewPlugin';
import { MatchBlockNode } from '@admin/molecules/RichEditor/Editor/plugins/MatchBlockPlugin';
import { PlaceholderNode } from '@admin/molecules/RichEditor/Editor/plugins/MatchBlockPlugin/PlaceholderNode';
import { MatchDetailSectionNode } from '@admin/molecules/RichEditor/Editor/plugins/MatchDetailSectionPlugin';
import { MediaBlockNode } from '@admin/molecules/RichEditor/Editor/plugins/MediaBlockPlugin';
import { PaywallNode } from '@admin/molecules/RichEditor/Editor/plugins/PaywallPlugin';
import { PostNode } from '@admin/molecules/RichEditor/Editor/plugins/XPlugin';
import { YoutubeNode } from '@admin/molecules/RichEditor/Editor/plugins/YoutubePlugin';
import {
    ArticleBlockPatch,
    ArticleBlockType,
    IframeBlockPatch,
    ImageBlockPatch,
    JobsOverviewBlockPatch,
    MatchDetailSectionBlockPatch,
    MatchListBlockPatch,
    MediaBlockPatch,
    PaywallBlockPatch,
    RichTextBlockPatch,
    XPostBlockPatch,
    YoutubeBlockPatch,
} from '@common/clients/api';

const mergeAllRichTextBlocks = (blocks: ArticleBlockPatch[]): ArticleBlockPatch[] => {
    const mergedBlocks: ArticleBlockPatch[] = [];
    let mergedText = '';

    for (const block of blocks) {
        if (block.type === ArticleBlockType.RICH_TEXT_BLOCK) {
            mergedText += block.text;
        } else {
            if (mergedText) {
                mergedBlocks.push({
                    type: ArticleBlockType.RICH_TEXT_BLOCK,
                    text: mergedText,
                } satisfies RichTextBlockPatch);
                mergedText = '';
            }
            mergedBlocks.push(block);
        }
    }

    if (mergedText) {
        mergedBlocks.push({
            type: ArticleBlockType.RICH_TEXT_BLOCK,
            text: mergedText,
        } satisfies RichTextBlockPatch);
    }

    return mergedBlocks;
};

function getArticleBlock(child: SerializedCustomNode<ArticleBlockPatch>): ArticleBlockPatch {
    /**
     * TODO: Remove the as casting
     * @description Serializes the node to a article block
     * note: overriding the type of child block because
     * the generated type of e.g. MatchDetailSectionBlock is
     * a static enum `ArticleBlockType` and not the run time value
     * `matchDetailSectionBlock`. So although run time will be as
     * expected, type will give an error because the enum is not
     * narrow enough. To mitigate this, we override the type of
     * each child with the expected run time value.
     */
    const serializers: Record<string, (node: SerializedCustomNode<ArticleBlockPatch>) => ArticleBlockPatch> =
        {
            [ArticleBlockType.MATCH_DETAIL_SECTION_BLOCK]: (node) => {
                return {
                    ...MatchDetailSectionNode.SerializedNodeToJSON(
                        node as SerializedCustomNode<MatchDetailSectionBlockPatch>,
                    ),
                    type: ArticleBlockType.MATCH_DETAIL_SECTION_BLOCK,
                };
            },
            [ArticleBlockType.MEDIA_BLOCK]: (node) => {
                return {
                    ...MediaBlockNode.SerializedNodeToJSON(node as SerializedCustomNode<MediaBlockPatch>),
                    type: ArticleBlockType.MEDIA_BLOCK,
                };
            },
            [ArticleBlockType.MATCH_LIST_BLOCK]: (node) => {
                return {
                    ...MatchBlockNode.SerializedNodeToJSON(node as SerializedCustomNode<MatchListBlockPatch>),
                    type: ArticleBlockType.MATCH_LIST_BLOCK,
                };
            },
            [ArticleBlockType.IFRAME_BLOCK]: (node) => {
                return {
                    ...IframeNode.SerializedNodeToJSON(node as SerializedCustomNode<IframeBlockPatch>),
                    type: ArticleBlockType.IFRAME_BLOCK,
                };
            },
            [ArticleBlockType.JOBS_OVERVIEW_BLOCK]: (node) => {
                return {
                    ...JobsOverviewNode.SerializedNodeToJSON(
                        node as SerializedCustomNode<JobsOverviewBlockPatch>,
                    ),
                    type: ArticleBlockType.JOBS_OVERVIEW_BLOCK,
                };
            },
            [ArticleBlockType.X_POST_BLOCK]: (node) => {
                return {
                    ...PostNode.SerializedNodeToJSON(node as SerializedCustomNode<XPostBlockPatch>),
                    type: ArticleBlockType.X_POST_BLOCK,
                };
            },
            [ArticleBlockType.YOUTUBE_BLOCK]: (node) => {
                return {
                    ...YoutubeNode.SerializedNodeToJSON(node as SerializedCustomNode<YoutubeBlockPatch>),
                    type: ArticleBlockType.YOUTUBE_BLOCK,
                };
            },
            [ArticleBlockType.IMAGE_BLOCK]: (node) => {
                return {
                    ...ImageBlockNode.SerializedNodeToJSON(node as SerializedCustomNode<ImageBlockPatch>),
                    type: ArticleBlockType.IMAGE_BLOCK,
                };
            },
            [ArticleBlockType.PAYWALL_BLOCK]: (node) => {
                return {
                    ...PaywallNode.SerializedNodeToJSON(node as SerializedCustomNode<PaywallBlockPatch>),
                    type: ArticleBlockType.PAYWALL_BLOCK,
                };
            },
        };

    const serializer = serializers[child.type as keyof typeof serializers];

    if (!serializer) {
        return PlaceholderNode.SerializedNodeToJSON(child as SerializedCustomNode<ArticleBlockPatch>);
    }

    return serializer(child);
}

export const exportEditorStateToArticleBlocks = (
    editor: LexicalEditor,
    html: string,
): Array<ArticleBlockPatch> => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(html, 'text/html');
    const blocks = editor
        .toJSON()
        .editorState.root.children.map((child, index): ArticleBlockPatch | undefined => {
            const isRichTextBlock = ['bullet', 'list', 'heading', 'paragraph'].includes(child.type);

            if (isRichTextBlock) {
                const element = htmlDoc.body.children[index];
                if (!element.innerHTML || element.innerHTML === '<br>') return undefined;
                return {
                    type: ArticleBlockType.RICH_TEXT_BLOCK,
                    text: element.outerHTML,
                } satisfies RichTextBlockPatch;
            }

            return getArticleBlock(child as SerializedCustomNode<ArticleBlockPatch>);
        })
        .filter((block): block is ArticleBlockPatch => block !== undefined);

    return mergeAllRichTextBlocks(blocks);
};
