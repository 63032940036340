import { MouseEvent, ReactNode } from 'react';

import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n/client';
import { ArticleBlockType } from '@common/clients/api';

import { ActionButtons } from '../ActionButtons';

import styles from './ArticleBlock.module.scss';

interface Props {
    children?: ReactNode;
    articleBlockType: ArticleBlockType;
    onClick?: (e: MouseEvent) => void;
    onDelete?: (e: MouseEvent) => void;
}

export const ArticleBlock = ({ children, articleBlockType, onClick, onDelete }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;

    return (
        <div className={styles.ArticleBlock}>
            <div></div>
            <div className={styles.content}>
                <h6>----------- {__translate(`articleBlock.title.${articleBlockType}`)} -----------</h6>
                <p>{__translate(`articleBlock.subTitle.${articleBlockType}`)}</p>
                {children}
            </div>
            <ActionButtons onEdit={onClick} onDelete={onDelete} />
        </div>
    );
};
