import { useState } from 'react';

import { Button, ButtonType } from '@admin/atoms/Button';
import { fileUpload } from '@admin/molecules/RichEditor/Editor/helpers/fileUpload';
import { processImageBlockAction } from '@admin/molecules/RichEditor/Editor/plugins/ImagePlugin/helpers/processImageBlockAction';
import {
    BaseModal,
    BaseModalFooter,
    BaseModalHeader,
    ModalActions,
    useModalContext,
} from '@admin/organisms/BaseModal';
import { Form } from '@admin/organisms/Form';
import FlashMessageUtil from '@admin/utils/FlashMessageUtil/FlashMessageUtil';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n/client';
import { Icon } from '@common/atoms/Icon';
import { ArticleBlockType, ImageBlockPatch } from '@common/clients/api';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';
import { useUserContext } from '@common/useUserContext/UserContextProvider';
import { WebpImage } from '@web/atoms/WebpImage';

import { formFields, getFileName, getImageDimensions, isUploadResponse } from './helpers';

import styles from './ImageBlockModal.module.scss';

interface Props {
    formData?: ImageBlockPatch;
    action?: ModalActions;
    onSubmit: (data: ImageBlockPatch) => void;
}

export const ImageBlockModal = ({ onSubmit, formData, action = ModalActions.create }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;
    const contextData = useContextData();
    const userContext = useUserContext();
    const { closeModal } = useModalContext(ArticleBlockType.IMAGE_BLOCK);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [savedUrl, setSavedUrl] = useState<string | null | undefined>(formData?.url);

    const flashes = FlashMessageUtil(__translate);

    const endpoint = (file: File, onProgress?: (progress: number) => void) =>
        fileUpload({
            contextData,
            userContext,
            file,
            onProgress,
        });

    const handleSuccess = (data: ImageBlockPatch) => {
        onSubmit(data);
        setSavedUrl(null);
        setIsSubmitting(false);
        closeModal();
    };

    const handleMissingImage = () => {
        setIsSubmitting(false);
        flashes.customError('insertImage.missingImage');
        return;
    };

    const handleError = () => {
        setIsSubmitting(false);
        flashes.customError('insertImage.uploadError');
    };

    const handleSubmit = async (data: ImageBlockPatch & { url?: string }) => {
        try {
            const requestBody: ImageBlockPatch = {
                type: ArticleBlockType.IMAGE_BLOCK,
                url: isUploadResponse(data.url) ? data.url.filename : savedUrl || '',
                caption: data.caption || '',
                credit: data.credit || '',
                alternativeText: data.alternativeText || '',
            };

            if (!isUploadResponse(data.url) && !savedUrl) {
                handleMissingImage();
            }

            let imageBlock: ImageBlockPatch;

            if (isUploadResponse(data.url)) {
                imageBlock = await processImageBlockAction({ contextData, userContext, requestBody });
                const dimensions = await getImageDimensions(imageBlock.url);
                imageBlock = {
                    ...imageBlock,
                    width: dimensions.width || 0,
                    height: dimensions.height || 0,
                };
            } else {
                imageBlock = requestBody;
            }
            handleSuccess(imageBlock);
        } catch (error) {
            handleError();
        }
    };

    const clickButton = () => {
        setIsSubmitting(true);
        doSubmit();
    };

    let doSubmit: () => void;
    const bindDoSubmit = (callback: () => void) => {
        doSubmit = callback;
    };

    return (
        <BaseModal className={styles.ImageBlockModal} contextKey={ArticleBlockType.IMAGE_BLOCK}>
            <BaseModalHeader contextKey={ArticleBlockType.IMAGE_BLOCK}>
                <div className={styles.title}>
                    <Icon.image svgProps={{ width: 28, height: 28, viewBox: '0 0 28 28' }} />
                    {__translate('insertImage.title')}
                </div>
            </BaseModalHeader>
            {!!savedUrl && (
                <div className={styles['preview-image-container']}>
                    <WebpImage
                        src={savedUrl}
                        alt={formData?.alternativeText || ''}
                        title={formData?.caption || ''}
                        defaultSize={WebpWidthEnum.WIDTH_450}
                        height={formData?.height || 0}
                        width={formData?.width || 0}
                    />
                    <p className={styles['file-name']}>{getFileName(formData?.url || '')}</p>
                </div>
            )}

            <Form<ImageBlockPatch, ImageBlockPatch>
                id={'image-block'}
                action={`image-block-${action}`}
                handleSubmit={handleSubmit}
                bindDoSubmit={bindDoSubmit}
                formFields={formFields(endpoint)}
                entity={formData}
            />

            <BaseModalFooter>
                <Button filled={true} loading={isSubmitting} type={ButtonType.submit} onClick={clickButton}>
                    {__translate('insertImage.addImage')}
                </Button>
            </BaseModalFooter>
        </BaseModal>
    );
};
