import { dataSourceEnvelope } from '@admin/molecules/AutoComplete/AutoComplete';
import { FieldType, FormFields } from '@admin/types';

export const formFields = (
    dataSource: (searchText: string) => Promise<(string | dataSourceEnvelope)[]>,
): FormFields => ({
    matchID: {
        type: FieldType.autoComplete,
        id: 'matches.0.id',
        dataSource: dataSource,
    },
});
