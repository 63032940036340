import { useEffect, useState } from 'react';

import { useTranslation } from '@cms/i18n/client';
import { Icon } from '@common/atoms/Icon';

import styles from './SearchBox.module.scss';

export interface Props {
    additionalClass?: string;
    currentSearch: string;
    onSubmit: (SearchText: string) => any;
}

export const SearchBox = (props: Props) => {
    const __components = useTranslation('components').t;
    const [value, setValue] = useState<string>(props.currentSearch);
    const [isActive, setIsActive] = useState<boolean>(false);

    const interval: number = 750; //ms

    const handleTimeOut = function () {
        if (value !== props.currentSearch) {
            props.onSubmit(value);
        } else {
            setIsActive(false);
        }
    };

    let timer: NodeJS.Timeout;

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timer = setTimeout(handleTimeOut, interval);
    }, [value]);

    useEffect(() => {
        if (!props.currentSearch) {
            setValue('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentSearch]);

    return (
        <div className={`${styles.SearchBox} ${props?.additionalClass || ''}`}>
            <Icon.search />
            <input
                type="search"
                value={value}
                autoFocus={isActive}
                name="search"
                autoComplete="off"
                placeholder={__components('SearchBox.placeholder')}
                onKeyDown={(e) => {
                    if (!isActive) setIsActive(true);
                    if (e.key === 'Enter') {
                        props.onSubmit(value);
                    }
                }}
                onBlur={(e) => {
                    if (e.relatedTarget === null && isActive) {
                        e.target.focus();
                    }
                }}
                onChange={(e) => {
                    const newValue = e.target.value;
                    clearTimeout(timer);
                    if (newValue !== value) {
                        setValue(newValue);
                    }
                }}
            />
        </div>
    );
};
