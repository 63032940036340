import { ArticleBlock } from '@admin/molecules/RichEditor/Editor/atoms/ArticleBlock';
import { ThemeOverride } from '@common/atoms/ThemeOverride';
import { ArticleBlockType, JobsOverviewBlockPatch } from '@common/clients/api';
import { ThemeID } from '@common/types/ThemeID';

interface Props extends JobsOverviewBlockPatch {
    onDelete: () => void;
}

export const JobsOverviewArticleBlock = ({ onDelete }: Props) => {
    return (
        <ThemeOverride themeID={ThemeID.ADMIN} data-testId="jobs-overview-article-block">
            <ArticleBlock onDelete={onDelete} articleBlockType={ArticleBlockType.JOBS_OVERVIEW_BLOCK} />
        </ThemeOverride>
    );
};
