import { VideoCustomParameters } from 'pxr-oneline';

import { logger } from '@common/logger';
import { LocalStorageKeys } from '@web/hooks/useLocalStorage/useLocalStorage';
import { sendGAEvent } from '@web/molecules/TrackingHandler';

import { Video, VideoProgressStorage } from '../../types';
import { Stats, StatsStorage } from './types';

export class VideoStatsHelper {
    // TODO: Remove this method +/- 1 week after releasing PB-4624
    private migrateOldValues = () => {
        const key = 'jw-player-viewed';
        const oldValues = window.localStorage.getItem(key);
        if (oldValues) {
            Object.entries(JSON.parse(oldValues)).forEach(([videoID, isViewed]) => {
                if (isViewed) this.storage[videoID] = 100;
            });
            window.localStorage.removeItem(key);
            this.saveStorage();
        }
    };

    /*
     * Singleton logic
     */
    private static _instance: VideoStatsHelper;

    static getInstance = () => {
        if (VideoStatsHelper._instance === undefined) {
            VideoStatsHelper._instance = new VideoStatsHelper();
        }
        return this._instance;
    };

    /*
     * Instance properties
     */
    private isDebug: boolean = true;
    private storage: VideoProgressStorage;
    private activeVideo: Video | undefined;

    private stats: StatsStorage = {
        video: {
            id: 'video',
            title: '',
            vendor: '',
            isActive: false,
            count: 0,
            duration: 0,
            lastTick: 0,
            lastPush: 0,
            pushSteps: [
                [1, 1], // after 1 second - every second
                [10, 5], // after 10 seconds - every 5 seconds
                [20, 10], // after 20 seconds - every 10 seconds
                [60, 20], // after 1 minute - every 20 seconds
                [60 * 3, 60], // after 3 minutes - every 60 seconds
            ],
        },
        ad: {
            id: 'ad',
            isActive: false,
            count: 0,
            requestCount: 0,
            duration: 0,
            lastTick: 0,
            lastPush: 0,
            pushSteps: [
                [1, 1], // after 1 seconds - every second
                [5, 5], // after 5 seconds - every 5 seconds
                [30, 20], // after 30 seconds - every 30 seconds
                [60 * 3, 60], // after 3 minutes - every 60 seconds
            ],
        },
        buffer: {
            id: 'buffer',
            isActive: false,
            count: 0,
            duration: 0,
            lastTick: 0,
            lastPush: 0,
            pushSteps: [
                [5, 1], // after 1 second - every second
                [5, 5], // after 5 second - every 5 seconds
            ],
        },
    };

    private constructor() {
        this.log('init');

        // Init local storage
        const _storage = window.localStorage.getItem(LocalStorageKeys.VIDEOPLAYER_PROGRESS);
        this.storage = _storage ? JSON.parse(_storage) : {};
        this.migrateOldValues();

        // Calculate stats every 250ms
        setInterval(this.onCalculateStats.bind(this), 250);
    }

    private log(method: string, ...args: any[]) {
        if (this.isDebug) logger.debug(`VideoStatsHelper.${method}`, ...args);
    }

    private saveStorage = () => {
        window.localStorage.setItem(LocalStorageKeys.VIDEOPLAYER_PROGRESS, JSON.stringify(this.storage));
        window.dispatchEvent(new Event('storage'));
    };

    public setProgress = (videoID: string, progress: number) => {
        const currentValue = this.storage[videoID];
        if (progress === 99) progress = 100;
        if (currentValue !== progress && !(progress <= 1 && currentValue === 100)) {
            this.storage[videoID] = progress;
            this.saveStorage();
        }
    };

    public getProgress = (videoID: string) => {
        return this.storage[videoID] || 0;
    };

    public getStats = (): StatsStorage => this.stats;

    public getActiveVideo = (): Video | undefined => this.activeVideo;

    public getFlattenedStats = (): VideoCustomParameters => ({
        video_title: this.activeVideo?.title || '',
        vendor: this.activeVideo?.vendor || '',
        video_count: this.stats.video.count,
        video_duration: this.stats.video.duration,
        ad_request_count: this.stats.ad.requestCount,
        ad_count: this.stats.ad.count,
        ad_duration: this.stats.ad.duration,
        buffer_duration: this.stats.buffer.duration,
    });

    /*
     * events
     */
    public setActiveVideo = (video: Video) => {
        this.activeVideo = video;
    };

    public startBuffer = () => {
        this.onStartStat(this.stats.buffer);
    };

    public startPlay = () => {
        this.onStartStat(this.stats.video);
    };

    public startAd = () => {
        this.onStartStat(this.stats.ad);
    };

    public increaseVideoCount = () => {
        this.stats.video.count++;
        this.onGoogleAnalyticsEvent('Video Count', 'Total', this.stats.video.count);
    };

    public increaseAdRequest = () => {
        this.stats.ad.requestCount++;
        this.onGoogleAnalyticsEvent('Ad', 'Requests', this.stats.ad.requestCount);
    };

    public increaseAdCount = () => {
        this.stats.ad.count++;
        this.onGoogleAnalyticsEvent('Ad', 'Impressions', this.stats.ad.count);
    };

    /*
     * start/stop
     */
    private onStartStat = (stats: Stats) => {
        if (stats.isActive) return;

        this.onStopStats();
        this.log('onStartStat', stats.id);
        stats.isActive = true;
        stats.lastTick = Date.now();
    };

    public onStopStats = () => {
        this.log('onStopStats');
        this.onCalculateStats();
        this.stats.video.isActive = false;
        this.stats.ad.isActive = false;
        this.stats.buffer.isActive = false;
    };

    /*
     * calculations
     */
    private onCalculateStats = () => {
        const currentTime = Date.now();
        Object.entries(this.stats).forEach(([_key, stats]) => {
            if (!stats.isActive) return;

            const diff = currentTime - stats.lastTick;
            stats.duration += diff;
            stats.lastTick = currentTime;

            const stepDiff = stats.duration - stats.lastPush;
            let stepLen = stats.pushSteps.length;
            while (stepLen--) {
                const durationThreshold = stats.pushSteps[stepLen][0] * 1000,
                    pushThreshold = stats.pushSteps[stepLen][1] * 1000;

                if (
                    stats.lastPush >= durationThreshold ||
                    (stats.lastPush === 0 && stats.duration >= durationThreshold)
                ) {
                    if (stepDiff >= pushThreshold) {
                        const durationInSeconds = Math.floor(stats.duration / 1000);
                        this.onGoogleAnalyticsEvent(
                            'Duration',
                            stats.id.charAt(0).toUpperCase() + stats.id.slice(1),
                            durationInSeconds,
                        );
                        stats.lastPush = stats.duration;
                    }
                    break;
                }
            }
            this.log('onCalculateStats', stats);
        });
    };

    public onGoogleAnalyticsEvent = (action: string, label: string, value?: number): void => {
        action = action.replace(/\s/g, '_').toLowerCase();
        this.log('onGoogleAnalyticsEvent', { action, label, value });
        sendGAEvent('event', action, {
            event_category: 'JW Player Video',
            event_label: label,
            value: value,
        });
    };
}
