import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n/client';
import { Icon } from '@common/atoms/Icon';

export const InsertIframe = () => {
    const __t = useTranslation(TranslationKey.richEditor).t;

    return (
        <>
            <Icon.iframe
                svgProps={{
                    width: 29,
                    height: 21,
                    viewBox: '0 0 29 21',
                }}
            />
            <span>{__t('insertIframe.button')}</span>
        </>
    );
};
