import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';

import { Placeholder } from '@admin/molecules/RichEditor/Editor/atoms';
import { ThemeOverride } from '@common/atoms/ThemeOverride';
import { useContextData } from '@common/useContextData';

import styles from './RichTextWrapperPlugin.module.scss';

export const RichTextWrapperPlugin = () => {
    const { themeID } = useContextData();
    return (
        <ThemeOverride themeID={themeID}>
            <div className={styles.RichTextWrapperPlugin}>
                <RichTextPlugin
                    contentEditable={<ContentEditable className={styles.input}></ContentEditable>}
                    placeholder={<Placeholder />}
                    ErrorBoundary={LexicalErrorBoundary}
                />
            </div>
        </ThemeOverride>
    );
};
