import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $createParagraphNode,
    $insertNodes,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand,
} from 'lexical';

import { useCommand } from '@admin/molecules/RichEditor/Editor/hooks';
import { ArticleBlockType, InstagramBlockPatch } from '@common/clients/api';

import { InstagramNode } from './InstagramNode';

export const INSERT_INSTAGRAM_COMMAND: LexicalCommand<string> = createCommand();

export const InstagramPlugin = (): null => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editor.hasNodes([InstagramNode])) {
            throw new Error('InstagramNode: InstagramNode not registered on editor');
        }
    }, [editor]);

    useCommand(
        INSERT_INSTAGRAM_COMMAND,
        (data: InstagramBlockPatch) => {
            const instagramNode = InstagramNode.__createNode({
                ...data,
                type: ArticleBlockType.INSTAGRAM_BLOCK,
            });
            const paragraph = $createParagraphNode();

            $insertNodes([instagramNode, paragraph]);
            return true;
        },
        COMMAND_PRIORITY_EDITOR,
    );

    return null;
};
