import { useMemo, useSyncExternalStore } from 'react';

export enum LocalStorageKeys {
    BINARY_POLL_RESULTS = 'binary-poll-results',
    IGNORED_USERS = 'ignored-users',
    IS_ARTICLE_BETA_ENABLED = 'isArticleBetaEnabled',
    LIKED_IDS = 'likedIDs',
    POLL_RESULTS = 'poll-results',
    PREFERRED_COMMENTS_ORDER = 'preferred-comments-order',
    VIDEOPLAYER_PROGRESS = 'videoplayer-progress',
}

const getSnapshot = (key: LocalStorageKeys) => () => {
    return window.localStorage.getItem(key);
};

const storeSnapshot =
    <T>(key: LocalStorageKeys) =>
    (value: T) => {
        if (value === undefined || value === null) {
            window.localStorage.removeItem(key);
        } else {
            window.localStorage.setItem(key, JSON.stringify(value));
        }
        window.dispatchEvent(new Event('storage'));
    };

const subscribe = (callback: () => void) => {
    window.addEventListener('storage', callback);
    return () => {
        window.removeEventListener('storage', callback);
    };
};

export const useLocalStorage = <T, InitialValue = T>(
    key: LocalStorageKeys,
    initialValue: InitialValue,
): [
    InitialValue extends () => infer R ? R : InitialValue,
    (value: InitialValue extends () => infer R ? R : InitialValue) => void,
] => {
    const storage = useSyncExternalStore(subscribe, getSnapshot(key), () => null);
    return useMemo(() => {
        const resolvedInitialValue = typeof initialValue === 'function' ? initialValue() : initialValue;
        const setValue = storeSnapshot<InitialValue extends () => infer R ? R : InitialValue>(key);
        const value = storage !== null ? JSON.parse(storage) : resolvedInitialValue;
        return [value, setValue];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storage, key]);
};
