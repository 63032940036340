/**
 * The contents of this file were imported from @jwplayer/jwplayer-react
 * and adapted for our codebase.
 */

import { JWPlayerProps, SetupConfig } from './types';

declare global {
    interface Window {
        jwplayer?: (id: string) => jwplayer.JWPlayer;
        jwDefaults: Record<string, unknown>;
    }
}

const configProps = new Set([
    'hlsjsProgressive',
    '__abSendDomainToFeeds',
    '_abZoomThumbnail',
    'advertising',
    'aboutlink',
    'abouttext',
    'aestoken',
    'allowFullscreen',
    'analytics',
    'androidhls',
    'aspectratio',
    'autoPause',
    'autostart',
    'base',
    'captions',
    'cast',
    'controls',
    'defaultBandwidthEstimate',
    'description',
    'displaydescription',
    'displayHeading',
    'displayPlaybackLabel',
    'displaytitle',
    'drm',
    'duration',
    'enableDefaultCaptions',
    'events',
    'file',
    'forceLocalizationDefaults',
    'fwassetid',
    'floating',
    'ga',
    'generateSEOMetadata',
    'height',
    'hlsjsConfig',
    'hlsjsdefault',
    'horizontalVolumeSlider',
    'image',
    'intl',
    'key',
    'listbar',
    'liveSyncDuration',
    'liveTimeout',
    'localization',
    'logo',
    'mediaid',
    'mute',
    'nextUpDisplay',
    'nextupoffset',
    'pad',
    'ph',
    'pid',
    'pipIcon',
    'playbackRateControls',
    'playbackRates',
    'playlist',
    'playlistIndex',
    'plugins',
    'preload',
    'qualityLabel',
    'qualityLabels',
    'recommendations',
    'related',
    'renderCaptionsNatively',
    'repeat',
    'safarihlsjs',
    'sdkplatform',
    'selectedBitrate',
    'setTimeEvents',
    'skin',
    'sharing',
    'sources',
    'stagevideo',
    'streamtype',
    'stretching',
    'title',
    'tracks',
    'type',
    'variations',
    'volume',
    'width',
    'withCredentials',
    'doNotTrack',
    'doNotTrackCookies',
    'images',
]);

let idIndex = -1;
// eslint-disable-next-line id-match
export const generateUniqueId = (): string => {
    idIndex++;
    const id = `jwplayer-${idIndex}`;
    return id;
};

export const generateConfig = (props: JWPlayerProps): SetupConfig => {
    const config: Record<string, unknown> = {};

    Object.keys(props).forEach((key) => {
        if (configProps.has(key)) config[key] = props[key as keyof JWPlayerProps];
    });

    return {
        ...(props.config ?? {}),
        ...config,
        isReactComponent: true,
    };
};
