import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { ArticleBlockType, MediaBlockPatch } from '@common/clients/api';

import { SerializedCustomNode } from '../CustomPluginNode/CustomNode';
import { MediaArticleBlock } from './MediaArticleBlock';

export class MediaBlockNode extends CustomNode<MediaBlockPatch, MediaBlockNode>(
    (data: MediaBlockPatch): MediaBlockNode => new MediaBlockNode(data),
) {
    static override __type = ArticleBlockType.MEDIA_BLOCK;

    constructor(data: MediaBlockPatch, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static override clone(node: MediaBlockNode): MediaBlockNode {
        return new MediaBlockNode(node.__data, node.__format, node.__key);
    }

    static JSONtoSerializedNode(block: MediaBlockPatch): SerializedCustomNode<MediaBlockPatch> {
        return {
            type: ArticleBlockType.MEDIA_BLOCK,
            data: {
                url: block.url,
                showAsCarousel: block.showAsCarousel,
                videofeedItemID: block.videofeedItemID,
                type: ArticleBlockType.MEDIA_BLOCK,
                title: block.title,
                duration: block.duration,
                thumbnail: block.thumbnail,
            },
            version: 1,
            format: 'left',
        };
    }

    handleOnChange(editor: LexicalEditor) {
        return (data: MediaBlockPatch) => {
            editor.update(() => {
                const writable: MediaBlockNode = this.getWritable();
                writable.__data.url = data.url;
                writable.__data.showAsCarousel = data.showAsCarousel;
                writable.__data.videofeedItemID = data.videofeedItemID;
                writable.__data.title = data.title;
                writable.__data.duration = data.duration;
                writable.__data.thumbnail = data.thumbnail;
            });
        };
    }

    override decorate(_editor: LexicalEditor, _config: EditorConfig) {
        return (
            <MediaArticleBlock
                onChange={this.handleOnChange(_editor)}
                onDelete={this.handleOnDelete(_editor)}
                block={this.__data}
            />
        );
    }
}
