import type { Translate } from '@cms/i18n';

import { dataSourceEnvelope } from '@admin/molecules/AutoComplete/AutoComplete';
import { FieldType, FormFields } from '@admin/types';

export const formFields = (
    t: Translate,
    dataSource: (searchText: string) => Promise<(string | dataSourceEnvelope)[]>,
): FormFields => ({
    videofeedItemID: {
        type: FieldType.autoComplete,
        id: 'videofeedItemID',
        title: t('mediaBlock.field.videoFeedItem'),
        dataSource: dataSource,
    },
    showAsCarousel: {
        type: FieldType.switch,
        id: 'showAsCarousel',
        title: t('mediaBlock.field.showAsCarousel'),
    },
});
