import { MouseEvent } from 'react';

import { Button } from '@admin/atoms/Button/Button';
import { Divider } from '@admin/molecules/RichEditor/Editor/plugins/ToolbarPlugin/atoms';
import { Icon } from '@common/atoms/Icon/Icon';

import styles from './ActionButtons.module.scss';

interface ActionButtonsProps {
    onEdit?: (e: MouseEvent) => void;
    onDelete?: (e: MouseEvent) => void;
}

export const ActionButtons = ({ onEdit, onDelete }: ActionButtonsProps) => (
    <div className={styles.ActionButtons}>
        <div className={styles.buttonGroup}>
            {onEdit ? (
                <Button className={styles.button} onClick={onEdit}>
                    <Icon.edit
                        svgProps={{
                            width: 16,
                            height: 16,
                            viewBox: '0 0 16 16',
                        }}
                    />
                </Button>
            ) : null}
            {onEdit && onDelete ? <Divider /> : null}
            {onDelete ? (
                <Button className={styles.button} onClick={onDelete}>
                    <Icon.trash
                        svgProps={{
                            width: 16,
                            height: 16,
                            viewBox: '0 0 13 16',
                        }}
                    />
                </Button>
            ) : null}
        </div>
    </div>
);
