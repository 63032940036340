import { LEXICAL_DATA_ATTR } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { ArticleBlockType } from '@common/clients/api';

interface NodeData {
    type: ArticleBlockType;
}

export const isDomNodeWithType = (domNode: HTMLDivElement, type: ArticleBlockType) => {
    if (!domNode.hasAttribute(LEXICAL_DATA_ATTR)) {
        return null;
    }

    const data = domNode.getAttribute(LEXICAL_DATA_ATTR);

    if (!data) {
        throw new Error(`isDomNodeWithType: is missing data on ${LEXICAL_DATA_ATTR}`);
    }

    const parsedData: NodeData = JSON.parse(data);

    if (!parsedData.type) {
        throw new Error(`isDomNodeWithType: is missing type on ${LEXICAL_DATA_ATTR}`);
    }

    return parsedData.type === type;
};
