import { useEffect, useState } from 'react';

import { TeamVsTeam } from '@admin/atoms/TeamVsTeam';
import { ArticleBlock } from '@admin/molecules/RichEditor/Editor/atoms/ArticleBlock';
import { getMatch } from '@admin/molecules/RichEditor/Editor/helpers';
import { ModalActions, useModalContext } from '@admin/organisms/BaseModal';
import { ThemeOverride } from '@common/atoms/ThemeOverride';
import { ArticleBlockType, Match, MatchListBlockPatch } from '@common/clients/api';
import { ThemeID } from '@common/types/ThemeID';
import { useContextData } from '@common/useContextData';

import { MatchBlockModal } from '.';

import styles from './MatchBlockArticleBlock.module.scss';

interface Props extends MatchListBlockPatch {
    onChange: (data: MatchListBlockPatch) => void;
    onDelete: () => void;
}

export const MatchBlockArticleBlock = ({ onChange, onDelete, matches, type }: Props) => {
    const { openModal } = useModalContext(ArticleBlockType.MATCH_LIST_BLOCK);
    const contextData = useContextData();
    const [match, setMatch] = useState<Match | null>();

    useEffect(() => {
        if (!matches.length) return;
        getMatch({ contextData, matchID: matches[0].id }).then(setMatch);
    }, [matches, contextData]);

    return (
        <ThemeOverride themeID={ThemeID.ADMIN} className={styles.MatchBlockArticleBlock}>
            <ArticleBlock
                onClick={openModal}
                onDelete={onDelete}
                articleBlockType={ArticleBlockType.MATCH_LIST_BLOCK}
            >
                {match ? (
                    <TeamVsTeam
                        className={styles.teamVsTeam}
                        kickOff={match.kickOff}
                        awayTeamName={match.away?.name}
                        homeTeamName={match.home?.name}
                    />
                ) : (
                    'no match selected'
                )}
            </ArticleBlock>
            <MatchBlockModal formData={{ matches, type }} onSubmit={onChange} action={ModalActions.update} />
        </ThemeOverride>
    );
};
