import { ImageBlock } from '@common/clients/api';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { WebpImage } from '@web/atoms/WebpImage';

import styles from './RichImageBlock.module.scss';

type RichImageBlockProps = {
    block: ImageBlock;
};

export const RichImageBlock = ({ block }: RichImageBlockProps) => {
    const { width, height, credit, alternativeText, caption, url } = block;

    return (
        <div className={styles.RichImageBlock}>
            <div className={styles['image-wrapper']}>
                <WebpImage
                    className="rich-content-image"
                    defaultSize={WebpWidthEnum.WIDTH_900}
                    src={url}
                    alt={alternativeText || ''}
                    height={height}
                    width={width}
                />
                {!!credit && <small className={styles.credit}>© {credit}</small>}
            </div>
            {!!caption && <p className={styles.caption}>{caption}</p>}
        </div>
    );
};
