import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n/client';
import { Icon } from '@common/atoms/Icon';

export const InsertMediaBlock = () => {
    const { t } = useTranslation(TranslationKey.richEditor);

    return (
        <>
            <Icon.camera
                svgProps={{
                    width: 28,
                    height: 28,
                    viewBox: '0 0 37 36',
                }}
            />
            <span>{t('insertMediaBlock.button')}</span>
        </>
    );
};
