export const urlFromIframe = (iframe: string) => {
    //Handles direct youtube URLs
    if (iframe.includes('youtube.com/watch?v=')) {
        try {
            const videoID = new URL(iframe).searchParams.get('v');
            return videoID ? `https://youtu.be/${videoID}` : '';
        } catch (error) {
            return '';
        }
    }

    //Handles ifram embed codes
    const srcMatch = iframe.match(/src=["']([^"']+)/);
    if (srcMatch) {
        return srcMatch[1];
    }

    // Handles direct URLs
    const urlMatch = iframe.match(/http[^"']+/);
    if (urlMatch) {
        return urlMatch[0];
    }

    return '';
};
