import { WebpWidthEnum } from '@common/types/WebpWidth';

export const getImageDimensions = (url: string): Promise<{ width: number; height: number }> => {
    const imageUrl = url.replace('{WebpWidth}', `${WebpWidthEnum.WIDTH_900}`);

    return new Promise((resolve) => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
            resolve({
                width: img.naturalWidth,
                height: img.naturalHeight,
            });
        };
    });
};
