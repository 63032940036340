import { memo, useCallback, useEffect, useState } from 'react';

import { Props, VideoPlayer as VideoPlayerBase } from './VideoPlayer';

export const Component = (props: Props) => {
    const [hasScrolled, setHasScrolled] = useState<boolean>(false);

    const handleScroll = useCallback(() => {
        setHasScrolled(true);
        window.removeEventListener('scroll', handleScroll);
    }, [setHasScrolled]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return hasScrolled ? <VideoPlayerBase {...props} /> : null;
};

Component.displayName = 'OnScrollVideoPlayer';
export const OnScrollVideoPlayer = memo(Component);
