import { FileService, ImageBlockPatch } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData } from '@common/defaults';
import { UserContext } from '@common/defaults/UserContext';

export const processImageBlockAction = async ({
    contextData,
    userContext,
    requestBody,
}: {
    contextData: ContextData;
    userContext: UserContext | undefined;
    requestBody: ImageBlockPatch;
}): Promise<ImageBlockPatch> => {
    const { data } = await ApiService({
        contextData,
        userContext,
        isClientSide: true,
        service: FileService,
    }).processImageBlockFileUpload({ body: requestBody, throwOnError: true });

    return data;
};
