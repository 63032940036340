import { ActionButtons } from '@admin/molecules/RichEditor/Editor/atoms/ActionButtons';
import { ModalActions, useModalContext } from '@admin/organisms/BaseModal';
import { ThemeOverride } from '@common/atoms/ThemeOverride';
import { ArticleBlockType, ImageBlockPatch } from '@common/clients/api';
import { ThemeID } from '@common/types/ThemeID';
import { RichImageBlock } from '@web/molecules/RickImageBlock/RichImageBlock';

import { ImageBlockModal } from './ImageBlockModal';

import styles from './ImageArticleBlock.module.scss';

interface Props {
    onChange: (data: ImageBlockPatch) => void;
    onDelete: () => void;
    block: ImageBlockPatch;
}

interface ImageData {
    url: { filename: string; destination: string };
}
export type FormEntity = ImageBlockPatch & ImageData;

export const ImageArticleBlock = ({ onChange, onDelete, block }: Props) => {
    const { openModal } = useModalContext(ArticleBlockType.IMAGE_BLOCK);

    if (!block) {
        return null;
    }

    return (
        <ThemeOverride themeID={ThemeID.ADMIN}>
            <div className={styles.ImageArticleBlockWrapper}>
                <RichImageBlock block={block} />
                <ActionButtons onEdit={openModal} onDelete={onDelete} />
            </div>
            <ImageBlockModal formData={block} onSubmit={onChange} action={ModalActions.update} />
        </ThemeOverride>
    );
};
