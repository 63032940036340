import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { BaseModalProvider } from '@admin/organisms/BaseModal';
import { ArticleBlockType, MatchDetailSectionBlockPatch } from '@common/clients/api';

import { MatchDetailSectionArticleBlock } from '.';

export class MatchDetailSectionNode extends CustomNode<MatchDetailSectionBlockPatch, MatchDetailSectionNode>(
    (data: MatchDetailSectionBlockPatch): MatchDetailSectionNode => new MatchDetailSectionNode(data),
) {
    static override __type = ArticleBlockType.MATCH_DETAIL_SECTION_BLOCK;

    constructor(data: MatchDetailSectionBlockPatch, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static override clone(node: MatchDetailSectionNode): MatchDetailSectionNode {
        return new MatchDetailSectionNode(node.__data, node.__format, node.__key);
    }

    handleOnChange(editor: LexicalEditor) {
        return (data: MatchDetailSectionBlockPatch) => {
            editor.update(() => {
                const writable: MatchDetailSectionNode = this.getWritable();

                writable.__data.showOdds = data.showOdds;
                writable.__data.matchID = data.matchID;
            });
        };
    }

    override decorate(editor: LexicalEditor, _config: EditorConfig) {
        return (
            <BaseModalProvider>
                <MatchDetailSectionArticleBlock
                    {...this.__data}
                    onChange={this.handleOnChange(editor)}
                    onDelete={this.handleOnDelete(editor)}
                />
            </BaseModalProvider>
        );
    }
}
