import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { InstagramPost } from '@common/atoms/InstagramPost';
import { ArticleBlockType, InstagramBlockPatch } from '@common/clients/api';

import { SerializedCustomNode } from '../CustomPluginNode/CustomNode';

export class InstagramNode extends CustomNode<InstagramBlockPatch, InstagramNode>(
    (data: InstagramBlockPatch): InstagramNode => new InstagramNode(data),
) {
    static override __type = ArticleBlockType.INSTAGRAM_BLOCK;

    constructor(data: InstagramBlockPatch, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static JSONtoSerializedNode(block: InstagramBlockPatch): SerializedCustomNode<InstagramBlockPatch> {
        return {
            type: ArticleBlockType.INSTAGRAM_BLOCK,
            data: {
                postID: block.postID,
                url: block.url,
                embedBlock: block.embedBlock,
                type: ArticleBlockType.INSTAGRAM_BLOCK,
            },
            version: 1,
            format: 'left',
        };
    }

    static override clone(node: InstagramNode): InstagramNode {
        return new InstagramNode(node.__data, node.__format, node.__key);
    }

    override decorate(_editor: LexicalEditor, _config: EditorConfig) {
        return <InstagramPost postID={this.__data.postID || ''} />;
    }
}
