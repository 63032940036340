import { ArticleBlock } from '@admin/molecules/RichEditor/Editor/atoms/ArticleBlock';
import { ModalActions, useModalContext } from '@admin/organisms/BaseModal';
import { ThemeOverride } from '@common/atoms/ThemeOverride';
import { ArticleBlockType, IframeBlockPatch } from '@common/clients/api';
import { ThemeID } from '@common/types/ThemeID';

import { EmbedIframeModal } from './EmbedIframeModal';

interface Props extends IframeBlockPatch {
    onChange: (data: IframeBlockPatch) => void;
    onDelete: () => void;
}

export const IframeArticleBlock = ({ onChange, onDelete, url, type }: Props) => {
    const { openModal } = useModalContext(ArticleBlockType.IFRAME_BLOCK);

    if (!url) {
        return null;
    }

    return (
        <ThemeOverride themeID={ThemeID.ADMIN}>
            <ArticleBlock
                onClick={openModal}
                onDelete={onDelete}
                articleBlockType={ArticleBlockType.IFRAME_BLOCK}
            >
                <span>{url}</span>
            </ArticleBlock>
            <EmbedIframeModal formData={{ url, type }} onSubmit={onChange} action={ModalActions.update} />
        </ThemeOverride>
    );
};
