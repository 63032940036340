import styles from './Button.module.scss';

interface Props {
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    children?: React.ReactNode;
    disabled?: boolean;
}

export const Button = ({ onClick, children, disabled }: Props) => {
    return (
        <button onClick={onClick} className={styles.Button} disabled={disabled}>
            {children}
        </button>
    );
};
