import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $createParagraphNode,
    $insertNodes,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand,
} from 'lexical';

import { useCommand } from '@admin/molecules/RichEditor/Editor/hooks';
import { ArticleBlockType, ImageBlockPatch } from '@common/clients/api';

import { ImageBlockNode } from './ImageBlockNode';

export const INSERT_IMAGE_COMMAND: LexicalCommand<ImageBlockPatch> = createCommand();

export const ImageBlockPlugin = (): null => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editor.hasNodes([ImageBlockNode])) {
            throw new Error('ImageBlockPlugin: ImageNode not registered on editor');
        }
    }, [editor]);

    useCommand(
        INSERT_IMAGE_COMMAND,
        (data: ImageBlockPatch) => {
            const imageNode = ImageBlockNode.__createNode({
                ...data,
                type: ArticleBlockType.IMAGE_BLOCK,
            });
            const paragraph = $createParagraphNode();

            $insertNodes([imageNode, paragraph]);
            return true;
        },
        COMMAND_PRIORITY_EDITOR,
    );

    return null;
};
