import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $createParagraphNode,
    $insertNodes,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand,
} from 'lexical';

import { useCommand } from '@admin/molecules/RichEditor/Editor/hooks';
import { ArticleBlockType, PaywallBlockPatch } from '@common/clients/api';

import { useHasPaywall } from './hooks/useHasPaywall';
import { PaywallNode } from './PaywallNode';

export const INSERT_PAYWALL_COMMAND: LexicalCommand<string> = createCommand();

const VALIDATION_ERROR = new Error('Only one paywall block is allowed per article');
const REGISTRATION_ERROR = new Error('PaywallPlugin: PaywallNode not registered on editor');

export const PaywallPlugin = (): null => {
    const [editor] = useLexicalComposerContext();
    const hasExistingPaywall = useHasPaywall();

    useEffect(() => {
        if (!editor.hasNodes([PaywallNode])) {
            throw REGISTRATION_ERROR;
        }
    }, [editor]);

    useCommand(
        INSERT_PAYWALL_COMMAND,
        (data: PaywallBlockPatch) => {
            if (hasExistingPaywall) {
                throw VALIDATION_ERROR;
            }

            const paywallNode = PaywallNode.__createNode({
                ...data,
                type: ArticleBlockType.PAYWALL_BLOCK,
            });
            const paragraph = $createParagraphNode();

            $insertNodes([paywallNode, paragraph]);
            return true;
        },
        COMMAND_PRIORITY_EDITOR,
    );

    return null;
};
