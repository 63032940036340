import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { XPost } from '@common/atoms/XPost';
import { ArticleBlockType, XPostBlockPatch } from '@common/clients/api';

import { SerializedCustomNode } from '../CustomPluginNode/CustomNode';

export class PostNode extends CustomNode<XPostBlockPatch, PostNode>(
    (data: XPostBlockPatch): PostNode => new PostNode(data),
) {
    static override __type = ArticleBlockType.X_POST_BLOCK;

    constructor(data: XPostBlockPatch, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static JSONtoSerializedNode(block: XPostBlockPatch): SerializedCustomNode<XPostBlockPatch> {
        return {
            type: ArticleBlockType.X_POST_BLOCK,
            data: {
                postID: block.postID,
                type: ArticleBlockType.X_POST_BLOCK,
            },
            version: 1,
            format: 'left',
        };
    }

    static override clone(node: PostNode): PostNode {
        return new PostNode(node.__data, node.__format, node.__key);
    }

    override decorate(_editor: LexicalEditor, _config: EditorConfig) {
        return <XPost data={{ postID: this.__data.postID }} />;
    }
}
