import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from '@cms/i18n/client';
import { ReactPortal } from '@common/atoms/ReactPortal';
import { PlatformID, VideofeedConfig } from '@common/clients/api';
import { logger } from '@common/logger';
import { useContextData } from '@common/useContextData';

import { CountdownNotice } from './atoms/CountdownNotice';
import { ForwardButton } from './atoms/ForwardButton';
import { DidMountCallbackArguments, JWPlayerComponent, JWPlayerProps } from './atoms/JWPlayer';
import { PrebidScript } from './atoms/PrebidScript';
import { VideoAdsHelper } from './utils/VideoAdsHelper';
import { VideoStatsHelper } from './utils/VideoStatsHelper';
import { PlayVideoCallback, Video } from './types';

import styles from './VideoPlayer.module.scss';

/** The jwplayer version*/
const JWPLAYER_VERSION = '8.32.0';

/** The number of seconds when to show the countdown notification for small videos*/
const COUNTDOWN_SECONDS_SMALL_VIDEO = 5;

/** The number of seconds when to show the countdown notification for large videos*/
const COUNTDOWN_SECONDS_LARGE_VIDEO = 10;

/** The minimum seconds when a video is not considered small*/
const COUNTDOWN_MIN_LENGHT_LARGE_VIDEO = 60;

/** Countdown container id where the reactPortal will load countdown widget*/
const COUNTDOWN_CONTAINER_ID = '_next_widget_jw-countdown-notice';

const getPlatformSkin = (platformID: PlatformID): jwplayer.SkinConfig => ({
    timeslider: {
        rail: 'rgba(var(--lightSectionBack), 0.2)',
        progress: platformID === PlatformID.VI ? 'rgb(var(--mainColorLighter))' : 'rgb(var(--mediaColor))',
    },
});

export interface Props extends Partial<JWPlayerProps> {
    playlist: Video[];
    isAutoPlay?: boolean;
    isPreload?: boolean;
    videofeedConfig?: VideofeedConfig;
    setActiveVideoID?: (videoID: string) => void;
    registerPlayVideoCallback?: (callback: PlayVideoCallback) => void;
}

export const Component = ({
    videofeedConfig,
    isAutoPlay,
    isPreload,
    setActiveVideoID,
    registerPlayVideoCallback,
    ...props
}: Props) => {
    const context = useContextData();
    const [isPlayingAd, setIsPlayingAd] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [allowSharing, setAllowSharing] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<number>(0);
    const statsHelper = useRef<VideoStatsHelper>(VideoStatsHelper.getInstance());
    const adsHelper = useRef<VideoAdsHelper>(
        VideoAdsHelper.getInstance(context, videofeedConfig, statsHelper),
    );
    const player = useRef<jwplayer.JWPlayer>(null);
    const activeVideo = useRef<Video>(null);
    const previousActiveVideo = useRef<Video>(null);
    const allowAdRequest = useRef<boolean>(isAutoPlay ?? false);
    const countdownContainerRef = useRef<HTMLDivElement | null>(null);
    const __actions = useTranslation('actions').t;

    const countdownID = useMemo(
        () => `${COUNTDOWN_CONTAINER_ID}-${player.current?.getConfig().id}`,
        [player],
    );

    const playlistIndex = useCallback(() => {
        let playlistIndex = 0;
        props.playlist.find((video, index) => {
            if (video.mediaid === activeVideo.current?.mediaid) playlistIndex = index;
        });
        return playlistIndex;
    }, [props.playlist, activeVideo]);

    /*
     * jwplayer events
     */
    const onForwardPressed = useCallback(() => {
        if (!player.current) return;
        player.current.seek(player.current.getPosition() + 10);
    }, []);

    const onPlayerMounted = useCallback(
        ({ player: _player }: DidMountCallbackArguments) => {
            player.current = _player;

            _player.addButton(
                ForwardButton,
                __actions('skip-time', { time: __actions('datetime:seconds', { num: 10 }) }),
                onForwardPressed,
                'forward',
                'jw-icon-forward',
            );
        },
        [__actions, onForwardPressed],
    );

    const onPlayerUnmounted = useCallback(() => {
        player.current = null;
    }, []);

    const onError = useCallback((e: jwplayer.ErrorParam) => {
        logger.error(e);
        statsHelper.current.onGoogleAnalyticsEvent('Error', activeVideo.current?.file ?? '', e.code);
    }, []);

    const onPlaylistItem = useCallback(
        ({ item }: jwplayer.PlaylistItemParam) => {
            const video = item as Video;
            activeVideo.current = video;
            statsHelper.current.setActiveVideo(video);
            setActiveVideoID && activeVideo.current?.mediaid && setActiveVideoID(activeVideo.current.mediaid);
            setAllowSharing(!!video.link);
        },
        [setActiveVideoID],
    );

    const onMeta = useCallback((meta: jwplayer.DateRangeMetaData) => {
        if (!player.current || !statsHelper.current || !meta.duration || !activeVideo.current?.mediaid)
            return;

        const progress = statsHelper.current.getProgress(activeVideo.current.mediaid);
        if (progress > 1 && progress !== 100) {
            player.current.seek((progress / 100) * meta.duration);
        }
    }, []);

    const onUserActive = useCallback(async () => {
        allowAdRequest.current = true;
    }, []);

    const onBeforePlay = useCallback(async () => {
        if (
            allowAdRequest.current &&
            activeVideo.current &&
            previousActiveVideo.current?.mediaid !== activeVideo.current?.mediaid
        ) {
            const ad = await adsHelper.current.getAd(activeVideo.current);
            if (player.current && ad) {
                setIsPlayingAd(true);
                player.current.playAd(ad);
            } else {
                setIsPlayingAd(false);
            }
        }
    }, []);

    const onAutostartNotAllowed = useCallback(() => {
        if (!player.current) return;
        // try again if not yet muted
        if (!player.current.getMute()) {
            logger.debug('VideoPlayer.onAutostartNotAllowed', 'start on mute');
            player.current.setMute(true);
            player.current.play();
        }
    }, []);

    const onPlay = useCallback(async () => {
        setIsPlayingAd(false);
        statsHelper.current.startPlay();
        if (previousActiveVideo.current?.mediaid !== activeVideo.current?.mediaid) {
            statsHelper.current.increaseVideoCount();
            setActiveVideoID && activeVideo.current?.mediaid && setActiveVideoID(activeVideo.current.mediaid);
            previousActiveVideo.current = activeVideo.current;
        }
    }, [setActiveVideoID]);

    const onPause = useCallback(() => {
        statsHelper.current.onStopStats();
    }, []);

    const onTime = useCallback(
        (e: jwplayer.TimeParam) => {
            if (!statsHelper.current || !activeVideo.current?.mediaid) return;
            const progress: number = Math.round((e.position / e.duration) * 100);
            statsHelper.current.setProgress(activeVideo.current.mediaid, progress);

            if (
                props.playlist &&
                props.playlist.length > 1 &&
                props.playlist.length !== playlistIndex() + 1
            ) {
                const remainingTime = e.duration - e.position;
                const countdownSeconds =
                    e.duration > COUNTDOWN_MIN_LENGHT_LARGE_VIDEO
                        ? COUNTDOWN_SECONDS_LARGE_VIDEO
                        : COUNTDOWN_SECONDS_SMALL_VIDEO;
                if (remainingTime <= countdownSeconds) setCountdown(Math.floor(remainingTime));
                else if (remainingTime > countdownSeconds && countdown !== 0) setCountdown(0);
            }
        },
        [countdown, playlistIndex, props.playlist],
    );

    const onBuffer = useCallback(() => {
        if (!statsHelper.current) return;
        statsHelper.current.startBuffer();
    }, []);

    const onAdRequest = useCallback(() => {
        statsHelper.current.increaseAdRequest();
    }, []);

    const onAdPlay = useCallback(() => {
        statsHelper.current.startAd();
    }, []);

    const onAdPause = useCallback(() => {
        statsHelper.current.onStopStats();
    }, []);

    const onAdImpression = useCallback(() => {
        statsHelper.current.increaseAdCount();
        adsHelper.current.onAdImpression();
    }, []);

    /*
     * Additional config values
     */

    const startingIndex = useMemo<number>(() => {
        if (props.playlistIndex) return props.playlistIndex;

        let startingIndex = 0;
        props.playlist.find((video, index) => {
            if (!video.mediaid) return false;
            const progress = statsHelper.current.getProgress(video.mediaid);
            if (progress < 100) {
                startingIndex = index;
                return true;
            }
            return false;
        });
        return startingIndex;
    }, [props.playlistIndex, props.playlist]);

    /*
     * Parent-child communication
     */

    const playVideo = useCallback<PlayVideoCallback>(
        (videoID: string) => {
            if (!props.playlist || !player.current || videoID === activeVideo.current?.mediaid) return;
            const index = props.playlist.findIndex((video) => video.mediaid === videoID);
            player.current.playlistItem(index);
        },
        [props.playlist],
    );

    useEffect(() => {
        if (!registerPlayVideoCallback) return;
        registerPlayVideoCallback(playVideo);
    }, [playVideo, registerPlayVideoCallback]);

    /*
     * Loading state
     */

    useEffect(() => {
        adsHelper.current.isLoaded.then(() => {
            setIsLoaded(true);
        });
    }, [context]);

    /*
     * Append countdown container
     */

    useEffect(() => {
        if (!countdownContainerRef.current && countdown) {
            const playerElement = document.getElementById(`#${countdownID}`);
            if (!playerElement) {
                const jwWrapper = document.querySelector(`#${player.current?.getConfig().id} .jw-wrapper`);

                if (jwWrapper) {
                    const countdownWrapper = document.createElement('div');
                    countdownWrapper.id = countdownID;

                    jwWrapper.appendChild(countdownWrapper);
                    countdownContainerRef.current = countdownWrapper;
                }
            }
        }
    }, [countdown, countdownID]);

    if (!isLoaded || !adsHelper.current) {
        return (
            <section className={styles.VideoPlayer}>
                <PrebidScript />
            </section>
        );
    }

    const classes = [styles.VideoPlayer, 'inverted'];
    if (!allowSharing) classes.push(styles['sharing-disabled']);
    classes.push(isPlayingAd ? styles['ad-mode'] : styles['video-mode']);

    const platformSkin = getPlatformSkin(context.platform.id);

    return (
        <section className={classes.join(' ')}>
            <PrebidScript />
            <JWPlayerComponent
                {...props}
                playlistIndex={startingIndex}
                library={`//ssl.p.jwpcdn.com/player/v/${JWPLAYER_VERSION}/jwplayer.js`}
                onPlayerMounted={onPlayerMounted}
                onPlayerUnmounted={onPlayerUnmounted}
                onError={onError}
                onPlaylistItem={onPlaylistItem}
                onMeta={onMeta}
                onUserActive={onUserActive}
                onBeforePlay={onBeforePlay}
                onAutostartNotAllowed={onAutostartNotAllowed}
                onPlay={onPlay}
                onPause={onPause}
                onTime={onTime}
                onBuffer={onBuffer}
                onAdRequest={onAdRequest}
                onAdPlay={onAdPlay}
                onAdPause={onAdPause}
                onAdImpression={onAdImpression}
                skin={platformSkin}
                config={{
                    preload: isPreload ? 'auto' : 'none',
                    width: '100%',
                    aspectratio: '16:9',
                    logo: undefined,
                    nextUpDisplay: false,
                    // @ts-expect-error
                    autostart: isAutoPlay ? 'viewable' : undefined,
                    mute: false,
                    abouttext: `©${new Date().getFullYear()} ${context.platform.name}`,
                    aboutlink: `https://${window.location.host}`,
                    key: context.platform.jwplayerKey,
                    ga: {},
                    related: {
                        displayMode: 'none',
                    },
                    cast: {},
                    intl: {
                        nl_NL: {
                            advertising: {
                                admessage: 'Deze advertentie duurt nog xx seconden.',
                                cuetext: 'Advertentie',
                                skipmessage: 'Overslaan in xx',
                            },
                        },
                    },
                    sharing: {
                        sites: ['twitter', 'facebook', 'email', 'linkedin', 'reddit'],
                    },
                    ...props.config,
                    ...adsHelper.current.getJWPlayerConfig(),
                    ...(isAutoPlay ? { floating: {} } : {}),
                }}
            />
            {countdown && countdownID ? (
                <ReactPortal containerID={`${countdownID}`}>
                    <CountdownNotice countdown={countdown} />
                </ReactPortal>
            ) : null}
        </section>
    );
};

Component.displayName = 'VideoPlayer';
export const VideoPlayer = memo(Component);
