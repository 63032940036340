import { ActionButtons } from '@admin/molecules/RichEditor/Editor/atoms/ActionButtons';
import { ModalActions } from '@admin/organisms/BaseModal';
import { ThemeOverride } from '@common/atoms/ThemeOverride';
import { MediaBlock as MediaBlockType, MediaBlockPatch } from '@common/clients/api';
import { ThemeID } from '@common/types/ThemeID';
import { MediaBlock } from '@web/molecules/RichNextContent/atoms/MediaBlock';

import { MediaBlockModal } from './MediaBlockModal';

interface Props {
    onChange: (data: MediaBlockPatch) => void;
    onDelete: () => void;
    block: MediaBlockType;
}

export const MediaArticleBlock = ({ onChange, onDelete, block }: Props) => {
    if (!block) {
        return null;
    }

    return (
        <ThemeOverride themeID={ThemeID.ADMIN}>
            <MediaBlock block={block} />
            <ActionButtons onDelete={onDelete} />
            <MediaBlockModal formData={block} onSubmit={onChange} action={ModalActions.update} />
        </ThemeOverride>
    );
};
