import { memo, useRef } from 'react';
import Script from 'next/script';

import { useContextData } from '@common/useContextData';

import { VideoAdsHelper } from '../utils/VideoAdsHelper';

export const Component = () => {
    const context = useContextData();
    const adsHelper = useRef<VideoAdsHelper>(VideoAdsHelper.getInstance(context));

    if (!adsHelper.current.getUsePrebid()) return null;

    return (
        <Script
            src="//ads.nextday.media/prebid/jw-video/7.43.0.min.js"
            onReady={adsHelper.current ? adsHelper.current.onLoadPrebidCompleted : undefined}
            onError={adsHelper.current ? adsHelper.current.onLoadPrebidError : undefined}
        />
    );
};

export const PrebidScript = memo(Component);
