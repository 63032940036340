import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { BaseModalProvider } from '@admin/organisms/BaseModal';
import { ArticleBlockType, JobsOverviewBlockPatch } from '@common/clients/api';

import { JobsOverviewArticleBlock } from '.';

export class JobsOverviewNode extends CustomNode<JobsOverviewBlockPatch, JobsOverviewNode>(
    (data: JobsOverviewBlockPatch): JobsOverviewNode => new JobsOverviewNode(data),
) {
    static override __type = ArticleBlockType.JOBS_OVERVIEW_BLOCK;

    constructor(data: JobsOverviewBlockPatch, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static override clone(node: JobsOverviewNode): JobsOverviewNode {
        return new JobsOverviewNode(node.__data, node.__format, node.__key);
    }

    override decorate(editor: LexicalEditor, _config: EditorConfig) {
        return (
            <BaseModalProvider>
                <JobsOverviewArticleBlock {...this.__data} onDelete={this.handleOnDelete(editor)} />
            </BaseModalProvider>
        );
    }
}
