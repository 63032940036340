import { MediaBlock as MediaBlockType, VideofeedStatus, VideofeedVendor } from '@common/clients/api';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { OnScrollVideoPlayer } from '@web/molecules/VideoPlayer/OnScrollLoadedVideoPlayer';
import { VideoCarousel as OnScrollVideoCarousel } from '@web/organisms/VideoCarousel/OnScrollLoadedVideoCarousel';

interface MediaBlockVideoProps {
    block: MediaBlockType;
}

export const MediaBlock = ({ block }: MediaBlockVideoProps) => {
    if (block.showAsCarousel)
        return (
            <OnScrollVideoCarousel
                mainVideo={{
                    ...block,
                    title: block.title || '',
                    vendor: VideofeedVendor.CUSTOM,
                    id: 1,
                    status: VideofeedStatus.ACCEPTED,
                }}
            />
        );

    return (
        <OnScrollVideoPlayer
            isPreload={false}
            isAutoPlay={block?.autoPlay}
            playlist={[
                {
                    ...block,
                    mediaid: block.title,
                    title: block.title || '',
                    file: block.url || '',
                    vendor: VideofeedVendor.CUSTOM,
                    sources: [{ file: block.url }],
                    image: (block?.thumbnail || '').replace('{WebpWidth}', `${WebpWidthEnum.WIDTH_900}`),
                    duration: block.duration,
                },
            ]}
        />
    );
};
