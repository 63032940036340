import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { YoutubeVideo } from '@common/atoms/YoutubeVideo';
import { ArticleBlockType, YoutubeBlockPatch } from '@common/clients/api';

import { SerializedCustomNode } from '../CustomPluginNode/CustomNode';

export class YoutubeNode extends CustomNode<YoutubeBlockPatch, YoutubeNode>(
    (data: YoutubeBlockPatch): YoutubeNode => new YoutubeNode(data),
) {
    static override __type = ArticleBlockType.YOUTUBE_BLOCK;

    constructor(data: YoutubeBlockPatch, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static JSONtoSerializedNode(block: YoutubeBlockPatch): SerializedCustomNode<YoutubeBlockPatch> {
        return {
            type: ArticleBlockType.YOUTUBE_BLOCK,
            data: {
                videoID: block.videoID,
                isPortrait: block.isPortrait,
                embedBlock: block.embedBlock,
                type: ArticleBlockType.YOUTUBE_BLOCK,
            },
            version: 1,
            format: 'left',
        };
    }

    static override clone(node: YoutubeNode): YoutubeNode {
        return new YoutubeNode(node.__data, node.__format, node.__key);
    }

    override decorate(_editor: LexicalEditor, _config: EditorConfig) {
        return (
            <YoutubeVideo videoID={this.__data.videoID || ''} isPortrait={this.__data.isPortrait || false} />
        );
    }
}
