import { ReactNode } from 'react';

import { getThemeClass } from '@common/defaults';
import { ThemeID } from '@common/types/ThemeID';

interface Props {
    children: ReactNode;
    className?: string;
    themeID: ThemeID;
}

export const ThemeOverride = ({ children, className, themeID, ...rest }: Props) => {
    const classNames = ['theme', getThemeClass(themeID)];

    if (className) {
        classNames.push(className);
    }

    return (
        <div className={classNames.join(' ')} {...rest}>
            {children}
        </div>
    );
};
