import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { ArticleBlockType, PaywallBlockPatch } from '@common/clients/api';

import { SerializedCustomNode } from '../CustomPluginNode/CustomNode';
import { PaywallArticleBlock } from './PaywallArticleBlock';

export class PaywallNode extends CustomNode<PaywallBlockPatch, PaywallNode>(
    (data: PaywallBlockPatch): PaywallNode => new PaywallNode(data),
) {
    static override __type = ArticleBlockType.PAYWALL_BLOCK;

    constructor(data: PaywallBlockPatch, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static JSONtoSerializedNode(block: PaywallBlockPatch): SerializedCustomNode<PaywallBlockPatch> {
        return {
            type: ArticleBlockType.PAYWALL_BLOCK,
            data: {
                paywallType: block.paywallType,
                type: ArticleBlockType.PAYWALL_BLOCK,
            },
            version: 1,
            format: 'left',
        };
    }

    static override clone(node: PaywallNode): PaywallNode {
        return new PaywallNode(node.__data, node.__format, node.__key);
    }

    override decorate(_editor: LexicalEditor, _config: EditorConfig) {
        return <PaywallArticleBlock {...this.__data} onDelete={this.handleOnDelete(_editor)} />;
    }
}
