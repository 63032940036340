import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { SerializedCustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode/CustomNode';
import { BaseModalProvider } from '@admin/organisms/BaseModal';
import { ArticleBlockPatch, ArticleBlockType } from '@common/clients/api';

import { PlaceholderArticleBlock } from '.';

export const createPlaceholderNode = (data: ArticleBlockPatch): PlaceholderNode => new PlaceholderNode(data);

export class PlaceholderNode extends CustomNode<ArticleBlockPatch, PlaceholderNode>(createPlaceholderNode) {
    static override __type = ArticleBlockType.EMBED_BLOCK;

    constructor(data: ArticleBlockPatch, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static JSONtoSerializedNode(block: ArticleBlockPatch): SerializedCustomNode<ArticleBlockPatch> {
        return {
            type: block.type,
            data: block,
            version: 1,
            format: 'left',
        };
    }

    static override clone(node: PlaceholderNode): PlaceholderNode {
        return new PlaceholderNode(node.__data, node.__format, node.__key);
    }

    override decorate(editor: LexicalEditor, _config: EditorConfig) {
        return (
            <BaseModalProvider>
                <PlaceholderArticleBlock
                    type={this.__data.type as ArticleBlockType}
                    onDelete={this.handleOnDelete(editor)}
                />
            </BaseModalProvider>
        );
    }
}
