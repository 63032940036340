import { useContext, useEffect, useRef } from 'react';
import * as React from 'react';

import { XPostContext } from './XPostProvider';

import styles from './XPost.module.scss';

interface Props {
    data: { postID?: string };
}

export const XPost = ({ data: { postID = '' } }: Props) => {
    const { setRef, state, scriptLoaded } = useContext(XPostContext);
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!postID || !containerRef.current) return;

        setRef?.(postID, containerRef.current);
    }, [postID]);

    useEffect(() => {}, [scriptLoaded]);

    return (
        <div className={styles.XPost}>
            {state[postID]?.loaded ? null : <div>Loading</div>}
            <div className={styles.frame} ref={containerRef} />
        </div>
    );
};
