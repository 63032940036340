import { useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalCommand } from 'lexical';

import { Button, ButtonType } from '@admin/atoms/Button';
import { BaseModal, BaseModalFooter, BaseModalHeader, useModalContext } from '@admin/organisms/BaseModal';
import { Form } from '@admin/organisms/Form';
import FlashMessageUtil from '@admin/utils/FlashMessageUtil/FlashMessageUtil';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n/client';
import { Logo } from '@common/atoms/Logo';
import { ArticleBlockType, InstagramBlockPatch } from '@common/clients/api';
import { instaEmbedAttribute, instaEmbedUrl, instaPostID } from '@common/regexp/instagram';
import { sanitizeHtml } from '@web/utils/sanitizeHtml';

import { formFields } from './helpers';
import { INSERT_INSTAGRAM_COMMAND } from './InstagramPlugin';

import styles from './EmbedInstagramModal.module.scss';

interface FormEntity {
    instagramPost?: string;
}

interface Props {
    formData?: FormEntity;
}

const matchRegex = (data: FormEntity, regex: RegExp) => {
    return data.instagramPost?.match(regex);
};

const getInstagramPostData = (
    data: FormEntity,
): {
    postID: string;
    url: string;
    embedBlock: string;
} => {
    const result = {
        postID: '',
        url: '',
        embedBlock: '',
    };

    if (!data?.instagramPost) {
        return result;
    }

    const linkMatch = matchRegex(data, instaEmbedUrl);
    if (linkMatch?.[0] && linkMatch?.[2]) {
        result.postID = linkMatch[2];
        result.url = linkMatch[0];
        return result;
    }

    const embedMatch = matchRegex(data, instaEmbedAttribute);
    if (embedMatch?.[2]) {
        result.postID = embedMatch[2];
        result.embedBlock = data.instagramPost;
        return result;
    }

    const postIDMatch = matchRegex(data, instaPostID);
    if (postIDMatch?.[0]) {
        result.postID = postIDMatch[0];
    }

    return result;
};

export const EmbedInstagramModal = ({ formData }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;

    const { closeModal } = useModalContext(ArticleBlockType.INSTAGRAM_BLOCK);
    const [editor] = useLexicalComposerContext();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const flashes = FlashMessageUtil(__translate);

    const handleSubmit = async (data: FormEntity) => {
        const { postID, url, embedBlock } = getInstagramPostData(data);

        if (!postID) {
            setIsSubmitting(false);
            flashes.customError('insertInstagramPost.missingPostID');
            return;
        }

        editor.dispatchCommand(INSERT_INSTAGRAM_COMMAND as LexicalCommand<InstagramBlockPatch>, {
            postID: postID,
            url: url,
            embedBlock: sanitizeHtml(embedBlock || ''),
            type: ArticleBlockType.INSTAGRAM_BLOCK,
        });

        setIsSubmitting(false);
        closeModal();
    };

    let doSubmit: () => void;
    const bindDoSubmit = (callback: () => void) => {
        doSubmit = callback;
    };

    const clickButton = () => {
        setIsSubmitting(true);
        doSubmit();
    };

    return (
        <BaseModal className={styles.EmbedInstagramModal} contextKey={ArticleBlockType.INSTAGRAM_BLOCK}>
            <BaseModalHeader contextKey={ArticleBlockType.INSTAGRAM_BLOCK}>
                <div className={styles.title}>
                    <Logo.instagram svgProps={{ width: 28, height: 28, viewBox: '0 0 28 28' }} />
                    {__translate('insertInstagramPost.title')}
                </div>
            </BaseModalHeader>

            <p>
                <b>{__translate('insertInstagramPost.label')}</b>
            </p>
            <div className={styles['instagram-post-form']}>
                <Form<FormEntity, FormEntity>
                    id={'insertInstagramPost'}
                    action={'insertInstagramPost'}
                    handleSubmit={handleSubmit}
                    bindDoSubmit={bindDoSubmit}
                    formFields={formFields}
                    entity={formData}
                />
                <BaseModalFooter className={styles['instagram-post-footer']}>
                    <Button
                        filled={true}
                        loading={isSubmitting}
                        onClick={clickButton}
                        type={ButtonType.submit}
                    >
                        {__translate('insertInstagramPost.insert')}
                    </Button>
                </BaseModalFooter>
            </div>
        </BaseModal>
    );
};
