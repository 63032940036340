import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { IframeArticleBlock } from '@admin/molecules/RichEditor/Editor/plugins/IframePlugin/IframeArticleBlock';
import { BaseModalProvider } from '@admin/organisms/BaseModal';
import { ArticleBlockType, IframeBlock, IframeBlockPatch } from '@common/clients/api';

import { SerializedCustomNode } from '../CustomPluginNode/CustomNode';

export class IframeNode extends CustomNode<IframeBlockPatch, IframeNode>(
    (data: IframeBlockPatch): IframeNode => new IframeNode(data),
) {
    static override __type = ArticleBlockType.IFRAME_BLOCK;

    constructor(data: IframeBlockPatch, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static JSONtoSerializedNode(block: IframeBlock): SerializedCustomNode<IframeBlockPatch> {
        return {
            type: ArticleBlockType.IFRAME_BLOCK,
            data: {
                url: block.url,
                embedBlock: block.embedBlock,
                type: ArticleBlockType.IFRAME_BLOCK,
            },
            version: 1,
            format: 'left',
        };
    }

    static override clone(node: IframeNode): IframeNode {
        return new IframeNode(node.__data, node.__format, node.__key);
    }

    handleOnChange(editor: LexicalEditor) {
        return (data: IframeBlockPatch) => {
            editor.update(() => {
                const writable: IframeNode = this.getWritable();

                writable.__data.url = data.url;
            });
        };
    }

    override decorate(editor: LexicalEditor, _config: EditorConfig) {
        return (
            <BaseModalProvider>
                <IframeArticleBlock
                    {...this.__data}
                    onChange={this.handleOnChange(editor)}
                    onDelete={this.handleOnDelete(editor)}
                />
            </BaseModalProvider>
        );
    }
}
