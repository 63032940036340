import { FieldType, FormFields } from '@admin/types';
import { TmpFile } from '@common/clients/api/types.gen';

export const formFields = (endpoint?: (file: File) => Promise<TmpFile>): FormFields => {
    return {
        url: {
            type: FieldType.file,
            id: 'url',
            title: false,
            fileType: 'image',
            acceptedMimeTypes: ['image/png', 'image/jpeg'],
            endpoint,
        },
        caption: {
            id: 'caption',
            type: FieldType.text,
            title: 'Caption',
        },
        credit: {
            id: 'credit',
            type: FieldType.text,
            title: 'Credits',
        },
        alternativeText: {
            id: 'alternativeText',
            type: FieldType.text,
            title: 'Alternative Text',
        },
    };
};
