/* eslint-disable id-match */
/**
 * The JWPlayer atom was added in PB-7142 to avoid a React version clash.
 * This component depends on @types/jwplayer and @cms/common/types/jwplayer-react.d.ts
 */

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Script from 'next/script';

import { JWPlayer, JWPlayerProps } from './types';
import { generateConfig, generateUniqueId } from './util';

declare global {
    interface Window {
        jwplayer?: (id: string) => jwplayer.JWPlayer;
        jwDefaults: Record<string, unknown>;
    }
}

interface ParamEventsConfig {
    onError?: (e: jwplayer.ErrorParam) => void;
    onPlaylistItem?: (e: jwplayer.PlaylistItemParam) => void;
    onTime?: (e: jwplayer.TimeParam) => void;
    onMeta?: (e: jwplayer.DateRangeMetaData) => void;
    onPlay?: (e: jwplayer.PlayParam) => void;
    onPause?: (e: jwplayer.PauseParam) => void;
    onMute?: (e: jwplayer.MuteParam) => void;
    onBuffer?: (e: jwplayer.BufferParam) => void;
    onAdPlay?: (e: jwplayer.AdPlayParam) => void;
    onAdPause?: (e: jwplayer.AdPlayParam) => void;
    onAdRequest?: (e: jwplayer.AdRequestParam) => void;
    onAdImpression?: (e: jwplayer.AdImpressionParam) => void;
    onAutostartNotAllowed?: (e: jwplayer.AutostartNotAllowedParam) => void;
}

interface NoParamEventsConfig {
    onUserActive?: () => void;
    onBeforePlay?: () => void;
}

interface EventsConfig extends ParamEventsConfig, NoParamEventsConfig {}

export interface JWPlayerConfig extends Partial<jwplayer.SetupConfig>, EventsConfig {}

const eventsWithCallback: Record<keyof ParamEventsConfig, keyof jwplayer.EventParams> = {
    onError: 'error',
    onPlaylistItem: 'playlistItem',
    onTime: 'time',
    onMeta: 'meta',
    onPlay: 'play',
    onPause: 'pause',
    onMute: 'mute',
    onBuffer: 'buffer',
    onAdPlay: 'adPlay',
    onAdPause: 'adPause',
    onAdRequest: 'adRequest',
    onAdImpression: 'adImpression',
    onAutostartNotAllowed: 'autostartNotAllowed',
};

const eventsWithoutCallback: Record<keyof NoParamEventsConfig, jwplayer.NoParamEvent> = {
    onBeforePlay: 'beforePlay',
    onUserActive: 'userActive',
};

export const JWPlayerComponent = (props: JWPlayerProps) => {
    const id = useMemo(() => props.id || generateUniqueId(), [props.id]);
    const playerRef = useRef<HTMLDivElement>(null);
    const [player, setPlayer] = useState<jwplayer.JWPlayer | null>(null);
    const [playerScriptReady, setPlayerScriptReady] = useState(false);

    const connectEventsToJWPlayer = useCallback(
        (_player: JWPlayer) => {
            Object.entries(eventsWithCallback).forEach(([prop, jwEvent]) => {
                const passedProperty = props[prop as keyof JWPlayerProps] as jwplayer.EventCallback<unknown>;
                if (passedProperty) {
                    _player.on(jwEvent, passedProperty);
                }
            });

            Object.entries(eventsWithoutCallback).forEach(([prop, jwEvent]) => {
                const passedProperty = props[prop as keyof JWPlayerProps] as VoidFunction;
                if (passedProperty) {
                    _player.on(jwEvent, passedProperty);
                }
            });
        },
        [props],
    );

    useEffect(() => {
        if (playerScriptReady && playerRef.current) {
            const setupConfig = { ...window.jwDefaults, ...generateConfig(props) };
            const _player = window.jwplayer!(playerRef.current.id).setup(setupConfig);
            setPlayer(_player);

            connectEventsToJWPlayer(_player);
            // TODO: jwPlayer.once(...) events are not implemented
            if (props.onPlayerMounted && _player) props.onPlayerMounted({ player: _player, id });
        }

        return () => {
            if (player) {
                if (props.onPlayerUnmounted) props.onPlayerUnmounted();
                // We don't have to disconnect listeners, remove() does that for us
                player.remove();
                setPlayerScriptReady(false);
                setPlayer(null);
            }
        };
    }, [playerScriptReady]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Script src={props.library} onReady={() => setPlayerScriptReady(true)} />
            <div id={id} ref={playerRef} />
        </>
    );
};
