import styles from './Switch.module.scss';

export interface Props {
    value: boolean;
    _inputProps: any;
}

export const Switch = ({ _inputProps, value }: Props) => {
    // placeholder is not allowed on type="checkbox"
    delete _inputProps.placeholder;

    return (
        <span className={styles.switch}>
            <input {..._inputProps} type="checkbox" checked={value} />
            <span className={`${styles.slider} ${styles.round}`}></span>
        </span>
    );
};
