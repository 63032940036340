import { ArticleBlock } from '@admin/molecules/RichEditor/Editor/atoms/ArticleBlock';
import { ThemeOverride } from '@common/atoms/ThemeOverride';
import { ArticleBlockType, PaywallBlockPatch } from '@common/clients/api';
import { ThemeID } from '@common/types/ThemeID';

interface Props extends PaywallBlockPatch {
    onDelete: () => void;
}

export const PaywallArticleBlock = ({ onDelete }: Props) => {
    return (
        <ThemeOverride themeID={ThemeID.ADMIN}>
            <ArticleBlock onDelete={onDelete} articleBlockType={ArticleBlockType.PAYWALL_BLOCK} />
        </ThemeOverride>
    );
};
