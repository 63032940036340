import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $createParagraphNode,
    $insertNodes,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand,
} from 'lexical';

import { useCommand } from '@admin/molecules/RichEditor/Editor/hooks';
import { ArticleBlockType, MediaBlockPatch } from '@common/clients/api';

import { MediaBlockNode } from './MediaBlockNode';

export const INSERT_MEDIA_BLOCK_COMMAND: LexicalCommand<string> = createCommand();

const REGISTRATION_ERROR = new Error('MediaBlockPlugin: MediaBlockNode not registered on editor');

export const MediaBlockPlugin = (): null => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editor.hasNodes([MediaBlockNode])) {
            throw REGISTRATION_ERROR;
        }
    }, [editor]);

    useCommand(
        INSERT_MEDIA_BLOCK_COMMAND,
        (data: MediaBlockPatch) => {
            const mediaBlockNode = MediaBlockNode.__createNode({
                ...data,
                type: ArticleBlockType.MEDIA_BLOCK,
            });
            const paragraph = $createParagraphNode();

            $insertNodes([mediaBlockNode, paragraph]);
            return true;
        },
        COMMAND_PRIORITY_EDITOR,
    );

    return null;
};
