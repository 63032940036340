import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n/client';
import { Icon } from '@common/atoms/Icon';

export const InsertImages = () => {
    const { t } = useTranslation(TranslationKey.richEditor);

    return (
        <>
            <Icon.image svgProps={{ width: 28, height: 28, viewBox: '0 0 28 28' }} />
            <span>{t('insertImage.button')}</span>
        </>
    );
};
