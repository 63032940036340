import { useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';

import { ArticleBlockType } from '@common/clients/api';

export const useHasPaywall = () => {
    const [editor] = useLexicalComposerContext();
    const [hasPaywall, setHasPaywall] = useState(false);

    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                const nodes = $getRoot().getChildren();
                for (const node of nodes) {
                    const isPaywallBlock =
                        ('getType' in node && node.getType() === ArticleBlockType.PAYWALL_BLOCK) ||
                        ('__data' in node &&
                            (node.__data as { type?: string })?.type === ArticleBlockType.PAYWALL_BLOCK);

                    if (isPaywallBlock) {
                        setHasPaywall(true);
                        return;
                    }
                }
                setHasPaywall(false);
            });
        });
    }, [editor]);

    return hasPaywall;
};
