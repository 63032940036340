import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $createParagraphNode,
    $insertNodes,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand,
} from 'lexical';

import { useCommand } from '@admin/molecules/RichEditor/Editor/hooks';
import { IframeNode } from '@admin/molecules/RichEditor/Editor/plugins/IframePlugin/IframeNode';
import { ArticleBlockType, IframeBlockPatch } from '@common/clients/api';

export const INSERT_IFRAME_COMMAND: LexicalCommand<string> = createCommand();

export const IframePlugin = (): null => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (!editor.hasNodes([IframeNode])) {
            throw new Error('IframePlugin: IframeNode not registered on editor');
        }
    }, [editor]);

    useCommand(
        INSERT_IFRAME_COMMAND,
        (data: IframeBlockPatch) => {
            const iframeNode = IframeNode.__createNode({ ...data, type: ArticleBlockType.IFRAME_BLOCK });
            const paragraph = $createParagraphNode();

            $insertNodes([iframeNode, paragraph]);
            return true;
        },
        COMMAND_PRIORITY_EDITOR,
    );

    return null;
};
