import { MutableRefObject } from 'react';
import { OneLine } from 'pxr-oneline';

import { VideofeedConfig } from '@common/clients/api';
import { ContextData } from '@common/defaults';
import { logger } from '@common/logger';
import { until } from '@common/utils/until';
import { fetchVideofeedConfig } from '@web/handlers/fetchVideofeedConfig';

import { Video } from '../types';
import { VideoStatsHelper } from './VideoStatsHelper';

declare const ndmOne: OneLine | undefined;
export class VideoAdsHelper {
    /*
     * Singleton logic
     */
    private static _instance: VideoAdsHelper;

    static getInstance = (
        context: ContextData,
        config?: VideofeedConfig,
        statsHelper?: MutableRefObject<VideoStatsHelper>,
    ) => {
        if (VideoAdsHelper._instance === undefined) {
            VideoAdsHelper._instance = new VideoAdsHelper(context, config, statsHelper);
        }
        return this._instance;
    };

    /*
     * Instance properties
     */
    private isDebug: boolean = true;
    public readonly isLoaded: Promise<void>;
    private config?: VideofeedConfig;
    private prebidIsLoaded: boolean = false;
    private usePrebid: boolean = true;
    private forceNextAd: boolean = true; // Always show an ad on the first video
    private currentAdSchedule: boolean[] = [];

    private gtag?: string;
    private xandrID?: string;
    private gpsID?: string;

    private prebidBiddingComplete?: boolean;
    private prebidVideoUrl?: string;

    // FIXME: these are not typed
    private prebidBids: any;

    private constructor(
        private readonly context: ContextData,
        config?: VideofeedConfig,
        private readonly statsHelper?: MutableRefObject<VideoStatsHelper>,
    ) {
        if (config) this.config = config;
        this.isLoaded = this.init();
    }

    init = async () => {
        return Promise.all([this.loadConfig(), this.loadPrebid()]).then(() => {});
    };

    private log = (method: string, ...args: any[]) => {
        if (this.isDebug) logger.debug(`VideoAdsHelper.${method}`, ...args);
    };

    /*
     * public getters
     */
    getUsePrebid = () => this.usePrebid;

    /*
     * Video Config
     */
    private loadConfig = async () => {
        if (!this.config) this.config = await fetchVideofeedConfig(this.context, true);
        return await this.onLoadConfigCompleted();
    };

    private onLoadConfigCompleted = async () => {
        this.log('onLoadConfigCompleted');
        this.gtag = this.config?.gtags ? this.config.gtags.default || '' : '';
        this.xandrID = this.config?.xandrIDs ? this.config.xandrIDs.default || '' : '';
        this.gpsID = this.config?.gpsIDs ? this.config.gpsIDs.default || '' : '';

        if (this.gtag || this.xandrID || this.gpsID) {
            this.usePrebid = true;
            await this.loadPrebid();
        } else {
            this.usePrebid = false;
            this.prebidIsLoaded = true;
        }
    };

    /*
     * Prebid
     */
    private loadPrebid = async () => {
        this.log('loadPrebid');
        await until(() => this.prebidIsLoaded);
    };

    public onLoadPrebidError = () => {
        this.log('onLoadPrebidError');
        this.usePrebid = false;
        this.prebidIsLoaded = true;
    };

    public onLoadPrebidCompleted = () => {
        if (this.prebidIsLoaded) return;
        this.log('onLoadPrebidCompleted');

        this.prebidBids = {
            xandr: {
                bidder: 'xandr',
                params: {
                    placementId: this.xandrID,
                },
            },
            GroupM_gps: {
                bidder: 'GroupM_gps',
                params: {
                    placementId: this.gpsID,
                },
            },
        };

        if (typeof ndmOne !== 'undefined' && this.prebidBids && this.gtag) {
            ndmOne.preBidAdUnit(this.prebidBids, this.gtag, this.isDebug);
        } else {
            logger.error('ndmOne is unknown');
            this.usePrebid = false;
            this.prebidIsLoaded = true;
            return;
        }

        this.prebidIsLoaded = true;
    };

    public getJWPlayerConfig = (): Partial<jwplayer.PlayerConfig> => {
        this.log('getJWPlayerConfig');
        return {
            advertising: this.usePrebid
                ? {
                      client: 'googima',
                      vpaidcontrols: true,
                      endstate: 'suspended',
                      outstream: false,
                      rules: {
                          startOnSeek: 'pre',
                          timeBetweenAds: 0,
                      },
                  }
                : {
                      client: 'vast',
                      rules: {
                          frequency: 1,
                          startOn: 1,
                          deferAds: {},
                      },
                      vpaidcontrols: true,
                  },
        };
    };

    public getAd = async (video: Video): Promise<string | undefined> => {
        this.log('onCheckAdSchedule', {
            forceNextAd: this.forceNextAd,
            // activeVideo: video,
            currentAdSchedule: this.currentAdSchedule,
            config: this.config,
        });

        // If ads are turned off due to ?ads=false
        if (/ads=false/.test(window.location.search)) {
            this.log('onCheckAdSchedule - skip ad due to `?ads=false`');
            return;
        }

        if (!this.config) {
            this.log('onCheckAdSchedule - no config');
            return;
        }

        // Refill the current ad schedule
        if (!this.currentAdSchedule.length && this.config.adSchedule) {
            this.currentAdSchedule = this.config.adSchedule.slice();
            this.log('onCheckAdSchedule - restart ad schedule', {
                currentAdSchedule: this.currentAdSchedule,
            });
        }

        // If the next ad step is false, skip it
        if (!this.forceNextAd && !this.currentAdSchedule[0]) {
            this.log('onCheckAdSchedule - skip ad since next up in schedule is `false`');
            this.currentAdSchedule.shift();
            return;
        }

        // If the video is shorter than e.g. 30 second, wait for the next video
        if (
            !this.forceNextAd &&
            video.duration &&
            this.config.minSeconds &&
            video.duration < this.config.minSeconds
        ) {
            this.log(
                `onCheckAdSchedule - skip ad since video duration is less than ${this.config.minSeconds} seconds`,
            );
            return;
        }

        // Make sure the next video we show an ad in case the returned value doesn't result in an ad impression
        this.forceNextAd = true;

        if (this.usePrebid) {
            this.xandrID = this.config.xandrIDs?.[video.vendor] || this.config.xandrIDs?.default;
            this.gpsID = this.config.gpsIDs?.[video.vendor] || this.config.gpsIDs?.default;
            if (!this.xandrID && !this.gpsID) {
                this.log('onCheckAdSchedule - no xandrID or gpsID found for this vendor');
                return;
            }

            this.prebidBids.xandr.params.placementId = this.xandrID;
            this.prebidBids.GroupM_gps.params.placementId = this.gpsID;
            this.gtag = this.config.gtags?.[video.vendor] || this.config.gtags?.default;
            if (!this.gtag) {
                this.log('onCheckAdSchedule - no gtag found for this vendor');
                return;
            }

            this.prebidBiddingComplete = false;
            this.log('onCheckAdSchedule - wait for .requestBids()');
            if (typeof ndmOne !== 'undefined') {
                ndmOne.requestVideoPlayerAds(this.onBiddingComplete.bind(this));
            }
            await until(() => this.prebidBiddingComplete !== false);
            this.log('onCheckAdSchedule - trigger ad', this.prebidVideoUrl);
            return this.prebidVideoUrl;
        }

        const vastTag = this.config.vastTags?.[video.vendor] || this.config.vastTags?.default;
        if (!this.config.vastTags) {
            this.log('onCheckAdSchedule - no vastTags setup for this domain');
            return;
        }

        if (!vastTag) {
            this.log('onCheckAdSchedule - no vastTag found for this vendor');
            return;
        }

        this.log('onCheckAdSchedule - trigger ad', vastTag);
        return vastTag;
    };

    public onBiddingComplete = (): void => {
        this.log('onBiddingComplete', {
            gtag: this.gtag,
            statsHelper: this.statsHelper?.current,
        });

        if (typeof ndmOne !== 'undefined' && this.gtag && this.statsHelper?.current?.getFlattenedStats()) {
            const videoUrlTemp = ndmOne.buildVideoUrl(
                [],
                this.gtag,
                this.statsHelper?.current?.getFlattenedStats(),
            );
            this.prebidVideoUrl = videoUrlTemp;
            this.prebidBiddingComplete = true;
        }
    };

    public onAdImpression = () => {
        this.log('onAdImpression');
        if (this.currentAdSchedule[0] === true) {
            this.log(
                'onAdImpression',
                'onCheckAdSchedule - ad slot has been filled, so remove next step from currentAdSchedule',
            );
            this.currentAdSchedule.shift();
        }
        this.forceNextAd = false;
    };
}
