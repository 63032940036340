import { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    className?: string;
}

export const Footer = ({ className = '', children }: Props) => {
    if (!children) return null;

    return <footer className={className}>{children}</footer>;
};
