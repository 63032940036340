import { memo, PropsWithChildren } from 'react';

import { useTranslation } from '@cms/i18n/client';

import styles from './CountdownNotice.module.scss';

export interface Props extends PropsWithChildren {
    countdown?: number;
}

export const Component = ({ countdown, children }: Props) => {
    const __actions = useTranslation('actions').t;
    return (children ?? (countdown as number) > 0) ? (
        <div className={[styles.CountdownNotice, 'countdown-notice'].join(' ')}>
            {children ?? __actions('countdown-notification', { time: countdown })}
        </div>
    ) : null;
};

export const CountdownNotice = memo(Component);
