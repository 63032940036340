import {
    Scope,
    VideofeedItem,
    VideofeedOrderBy,
    VideofeedService,
    VideofeedStatus,
} from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData } from '@common/defaults';

export const getVideofeedItems = async (
    contextData: ContextData,
    search: string,
): Promise<VideofeedItem[] | undefined> => {
    const { data: response } = await ApiService({
        contextData,
        isClientSide: true,
        service: VideofeedService,
        config: {
            throwOnError: true,
        },
    }).listVideofeedByDomainId({
        path: {
            contextID: contextData.context.id,
        },
        query: {
            search,
            scope: Scope.ADMIN,
            status: VideofeedStatus.ACTIVE,
            orderBy: VideofeedOrderBy.POSITION,
        },
        throwOnError: true,
    });

    return response?.data;
};
