import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { BaseModalProvider } from '@admin/organisms/BaseModal';
import { ArticleBlockType, ImageBlockPatch } from '@common/clients/api';

import { SerializedCustomNode } from '../CustomPluginNode/CustomNode';
import { ImageArticleBlock } from './ImageArticleBlock';

export class ImageBlockNode extends CustomNode<ImageBlockPatch, ImageBlockNode>(
    (data: ImageBlockPatch): ImageBlockNode => new ImageBlockNode(data),
) {
    static override __type = ArticleBlockType.IMAGE_BLOCK;

    constructor(data: ImageBlockPatch, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static override clone(node: ImageBlockNode): ImageBlockNode {
        return new ImageBlockNode(node.__data, node.__format, node.__key);
    }

    static JSONtoSerializedNode(block: ImageBlockPatch): SerializedCustomNode<ImageBlockPatch> {
        return {
            type: ArticleBlockType.IMAGE_BLOCK,
            data: {
                url: block.url,
                caption: block.caption,
                credit: block.credit,
                alternativeText: block.alternativeText,
                type: ArticleBlockType.IMAGE_BLOCK,
            },
            version: 1,
            format: 'left',
        };
    }

    handleOnChange(editor: LexicalEditor) {
        return (data: ImageBlockPatch) => {
            editor.update(() => {
                const writable: ImageBlockNode = this.getWritable();
                writable.__data.url = data.url;
                writable.__data.caption = data.caption;
                writable.__data.credit = data.credit;
                writable.__data.alternativeText = data.alternativeText;
            });
        };
    }
    override decorate(editor: LexicalEditor, _config: EditorConfig) {
        return (
            <BaseModalProvider>
                <ImageArticleBlock
                    block={this.__data}
                    onChange={this.handleOnChange(editor)}
                    onDelete={this.handleOnDelete(editor)}
                />
            </BaseModalProvider>
        );
    }
}
