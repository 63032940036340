import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n/client';
import { Logo } from '@common/atoms/Logo';

export const InsertYoutubeVideo = () => {
    const { t } = useTranslation(TranslationKey.richEditor);

    return (
        <>
            <Logo.youtube
                svgProps={{
                    width: 28,
                    height: 28,
                    viewBox: '0 0 28 28',
                }}
            />
            <span>{t('insertYoutubeVideo.button')}</span>
        </>
    );
};
