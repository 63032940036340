import { FileLocation } from '@common/clients/api';
import { FileService } from '@common/clients/api/sdk.gen';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData } from '@common/defaults/ContextData';
import { UserContext } from '@common/defaults/UserContext';

export const fileUpload = async ({
    contextData,
    userContext,
    file,
    onProgress,
}: {
    contextData: ContextData;
    userContext: UserContext;
    file: File | Blob;
    onProgress?: (progress: number) => void;
}) => {
    const service = ApiService({
        contextData,
        userContext,
        isClientSide: true,
        service: FileService,
        onUploadProgress: onProgress,
    });

    const { data } = await service.fileUploadFileUpload({
        body: { file },
        query: { fileLocation: FileLocation.TMP },
        throwOnError: true,
    });

    return data;
};
