export enum TranslationKey {
    common = 'common',
    components = 'components',
    curation = 'curation',
    faqCategories = 'faqCategories',
    faqs = 'faqs',
    pages = 'pages',
    propositions = 'propositions',
    richEditor = 'rich-editor',
    subscribe = 'subscribe',
    subscriptionGifts = 'subscription-gifts',
    tierGroups = 'tier-groups',
    tiers = 'tiers',
}
