import { Match, MatchService } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData } from '@common/defaults';

export const getMatches = async (contextData: ContextData, search: string): Promise<Match[] | undefined> => {
    const { data: response } = await ApiService({
        contextData,
        isClientSide: true,
        service: MatchService,
        config: {
            throwOnError: true,
        },
    }).searchMatch({ query: { search } });

    return response?.data;
};
