import { useState } from 'react';

import { ProgressBar } from '@admin/atoms/ProgressBar';
import { InputElementProps } from '@admin/molecules/InputElement/InputElement';
import { Translate } from '@cms/i18n';
import { useTranslation } from '@cms/i18n/client';
import { TmpFile } from '@common/clients/api';
import { logger } from '@common/logger';
import { ThumbnailWidth } from '@common/types/WebpWidth';
import { formatNumber } from '@common/utils/NumbersUtil';
import { WebpImage } from '@web/atoms/WebpImage';

import styles from './FileUpload.module.scss';

export interface Props extends InputElementProps {
    endpoint: (file: File, onProgress?: (progress: number) => void) => Promise<TmpFile>;
    fileType?: 'video' | 'image';
    acceptedMimeTypes?: string[];
}

const MB = 1000000;
export const MAX_FILESIZE = 300 * MB;
export const MAX_FILESIZE_PER_MINUTE = 10 * MB;
export const MAX_FILESIZE_PER_MINUTE_LONG = 25 * MB;
export const LONG_VIDEO_THRESHOLD = 60 * 5; // 5 min
export const DEFAULT_TIMEOUT = 5 * 60 * 1000; // 5 sec

const IMAGE_MIME_STRINGS = ['image/png', 'image/jpeg'];
const VIDEO_MIME_STRINGS = ['video/mp4', 'audio/mpeg'];

export const FileUpload = (props: Props) => {
    const [selectedFile, setSelectedFile] = useState<File>();
    const [selectedFileName, setSelectedFileName] = useState<string | undefined>();

    const [selectedVideoDuration, setSelectedVideoDuration] = useState<number | undefined>();
    const [loaded, setLoaded] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const __components = useTranslation('components').t;

    const resetUpload = (event: any) => {
        logger.debug('upload is discarded');
        event.target.value = null; // discard selected file
        setSelectedFile(undefined);
        setErrorMessage('');
    };

    const fileSelectHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        setErrorMessage(undefined);
        setSelectedVideoDuration(undefined);

        //validating the type, size amd number of uploaded videos
        if (
            !checkMimeType(event, props, setErrorMessage, __components) ||
            !checkFileSize(event, setErrorMessage, __components) ||
            !checkMultipleUploads(event, setErrorMessage, __components) ||
            !file
        ) {
            // @ts-ignore
            event.target.value = null; // discard selected file
            if (props?.onChange) props.onChange(null);
        } else {
            setSelectedFile(file);
            const fileData = await props.endpoint(file, setLoaded).catch(() => {
                setErrorMessage(__components('FileUpload.uploadError'));
            });

            if (fileData?.filename) {
                setSelectedFileName(fileData.filename);
                if (props.fileType === 'video') {
                    // create the video element but don't add it to the page
                    const vid = document.createElement('video');
                    // create url to use as the src of the video
                    const fileURL = URL.createObjectURL(file);
                    vid.src = fileURL;
                    vid.ondurationchange = function () {
                        logger.debug('Video duration: ', Math.ceil(vid.duration));
                        setSelectedVideoDuration(Math.ceil(vid.duration));
                        vid.remove();
                    };
                }

                if (props?.onChange) props.onChange(fileData);
            }
        }
    };

    // For longer videos we allow a higher bitrate since people often watch this on larger screens
    const maxFileSizePerMinute =
        selectedVideoDuration && selectedVideoDuration > LONG_VIDEO_THRESHOLD
            ? MAX_FILESIZE_PER_MINUTE_LONG
            : MAX_FILESIZE_PER_MINUTE;

    if (
        props.fileType === 'video' &&
        selectedVideoDuration &&
        selectedFile &&
        selectedFile.size &&
        (selectedFile.size / selectedVideoDuration) * 60 > maxFileSizePerMinute
    ) {
        const sizePerMinute = formatNumber((selectedFile.size / MB / selectedVideoDuration) * 60);
        const allowedSizePerMinute = formatNumber(maxFileSizePerMinute / MB);
        return (
            <div className={[styles.FileUpload, styles['error']].join(' ')}>
                <button onClick={resetUpload}>X</button>
                <span>
                    <b>File: </b>
                    {selectedFile.name.length <= 50
                        ? selectedFile.name
                        : selectedFile.name.slice(0, 60) + '....'}
                </span>
                <br />
                <span>
                    {__components('FileUpload.sizePerMinuteError', { sizePerMinute, allowedSizePerMinute })}
                </span>
            </div>
        );
    } else if (props.value && props.fileType === 'image' && !selectedFile) {
        return (
            <div style={{ display: 'flex' }}>
                <WebpImage
                    src={(props.value as string) || ''}
                    alt={''}
                    defaultSize={ThumbnailWidth.WIDTH_48}
                    width={ThumbnailWidth.WIDTH_48}
                    height={ThumbnailWidth.WIDTH_48}
                />
                <input
                    type="file"
                    name="file"
                    onChange={fileSelectHandler}
                    accept={props.acceptedMimeTypes ? props.acceptedMimeTypes.join(', ') : undefined}
                />
            </div>
        );
    } else if (!selectedFile) {
        return (
            <>
                <input
                    type="file"
                    name="file"
                    onChange={fileSelectHandler}
                    accept={props.acceptedMimeTypes ? props.acceptedMimeTypes.join(', ') : undefined}
                />
                {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : ''}
                <input name={props.id} type="hidden" value="" />
            </>
        );
    } else if (!selectedFileName) {
        return (
            <div
                className={[
                    styles.FileUpload,
                    loaded < 100 ? styles.uploading : styles['done-uploading'],
                ].join(' ')}
            >
                <span>
                    <b>File: </b>
                    {selectedFile.name.length <= 50
                        ? selectedFile.name
                        : selectedFile.name.slice(0, 60) + '....'}
                </span>

                <span>
                    <ProgressBar loaded={Number(loaded.toFixed(0))} />
                    <button onClick={resetUpload}>X</button>
                </span>
            </div>
        );
    } else {
        return (
            <div
                className={[
                    styles.FileUpload,
                    loaded < 100 ? styles.uploading : styles['done-uploading'],
                ].join(' ')}
            >
                <button onClick={resetUpload}>X</button>
                <span>
                    <b>File: </b>
                    {selectedFile.name.length <= 50
                        ? selectedFile.name
                        : selectedFile.name.slice(0, 60) + '....'}
                </span>
                <input
                    name={props.id + '.filename'}
                    type="hidden"
                    value={selectedFileName}
                    accept={props.acceptedMimeTypes ? props.acceptedMimeTypes.join(', ') : undefined}
                />
                {selectedVideoDuration ? (
                    <input name={props.id + '.duration'} type="hidden" value={selectedVideoDuration} />
                ) : (
                    ''
                )}
            </div>
        );
    }
};

//////////// validations //////////////
function checkMimeType(
    event: any,
    props: any,
    setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>,
    __components: Translate,
) {
    //getting file object
    const files = event.target.files;

    // list allow mime types
    let types: string[] = [];
    if (props.acceptedMimeTypes) {
        types = props.acceptedMimeTypes;
    } else if (props.fileType === 'video') {
        types = VIDEO_MIME_STRINGS; //other extensions can be added here
    } else if (props.fileType === 'image') {
        types = IMAGE_MIME_STRINGS; //other extensions can be added here
    }

    // compare file type find if doesn't matach
    if (types.every((type) => files[0].type !== type)) {
        setErrorMessage(__components('FileUpload.typeError', { type: files[0].type }));
        return false;
    }

    return true;
}

function checkMultipleUploads(
    event: any,
    setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>,
    __components: Translate,
) {
    if (event.target.files.length > 1) {
        setErrorMessage(__components('FileUpload.multiUploadError'));
        return false;
    } else {
        return true;
    }
}

function checkFileSize(
    event: any,
    setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>,
    __components: Translate,
) {
    const files = event.target.files;
    if (files[0].size > MAX_FILESIZE) {
        setErrorMessage(__components('FileUpload.sizeError', { name: files[0].name }));
        return false;
    }

    return true;
}
