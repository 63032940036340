import { useRef, useTransition } from 'react';

import { Button, ButtonType } from '@admin/atoms/Button';
import { dataSourceEnvelope } from '@admin/molecules/AutoComplete/AutoComplete';
import { getVideofeedItems } from '@admin/molecules/RichEditor/Editor/helpers/getVideofeedItems';
import {
    BaseModal,
    BaseModalFooter,
    BaseModalHeader,
    ModalActions,
    useModalContext,
} from '@admin/organisms/BaseModal';
import { Form } from '@admin/organisms/Form';
import FlashMessageUtil from '@admin/utils/FlashMessageUtil/FlashMessageUtil';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n/client';
import { Icon } from '@common/atoms/Icon';
import { ArticleBlockType, MediaBlockPatch, VideofeedItem } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

import { formFields } from './helpers/formFields';

import styles from './MediaBlockModal.module.scss';

interface FormEntity {
    videofeedItemID?: number;
    showAsCarousel?: boolean;
}

interface Props {
    formData?: FormEntity;
    action: ModalActions;
    onSubmit: (data: MediaBlockPatch) => void;
}

export const MediaBlockModal = ({ onSubmit, formData, action = ModalActions.create }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;
    const videofeedItemsRef = useRef<Record<string, VideofeedItem>>({});
    const { closeModal } = useModalContext(ArticleBlockType.MEDIA_BLOCK);
    const contextData = useContextData();
    const [isPending, startTransition] = useTransition();
    const flashes = FlashMessageUtil(__translate);

    const mapMatchesToSourceEnvelope = (videofeedItem: VideofeedItem) => {
        videofeedItemsRef.current[videofeedItem.id] = videofeedItem;

        return {
            text: videofeedItem.id,
            element: <span>{videofeedItem.title}</span>,
        };
    };

    const dataSource = async (searchText: string): Promise<dataSourceEnvelope[]> =>
        getVideofeedItems(contextData, searchText).then((videos) =>
            (videos || []).map(mapMatchesToSourceEnvelope),
        );

    const handleSubmit = async (data: FormEntity): Promise<void> => {
        const { videofeedItemID } = data;

        if (!videofeedItemID || !videofeedItemsRef.current[videofeedItemID]) {
            flashes.customError('insertMedia.videoFeedIDError');
            return;
        }

        const selectedVideo = videofeedItemsRef.current[videofeedItemID];

        startTransition(async () => {
            try {
                await onSubmit({
                    type: ArticleBlockType.MEDIA_BLOCK,
                    url: selectedVideo?.url || '',
                    videofeedItemID: selectedVideo.id,
                    showAsCarousel: data.showAsCarousel,
                    title: selectedVideo?.title,
                    duration: selectedVideo?.duration,
                    thumbnail: selectedVideo?.thumbnail,
                    vendor: selectedVideo?.vendor,
                });
                closeModal();
            } catch (error) {
                flashes.customError('insertMedia.submitError');
            }
        });

        closeModal();
    };

    const clickButton = () => {
        doSubmit();
    };

    let doSubmit: () => void;
    const bindDoSubmit = (callback: () => void) => {
        doSubmit = callback;
    };

    return (
        <BaseModal className={styles.MediaBlockModal} contextKey={ArticleBlockType.MEDIA_BLOCK}>
            <BaseModalHeader contextKey={ArticleBlockType.MEDIA_BLOCK}>
                <div className={styles.title}>
                    <Icon.camera svgProps={{ width: 28, height: 28, viewBox: '0 0 28 36' }} />
                    <span>{__translate('insertMediaBlock.title')}</span>
                </div>
            </BaseModalHeader>
            <Form<FormEntity, FormEntity>
                id={'insertMediaBlock'}
                action={`media-block-${action}`}
                handleSubmit={handleSubmit}
                bindDoSubmit={bindDoSubmit}
                formFields={formFields(__translate, dataSource)}
                entity={formData}
            />
            <BaseModalFooter>
                <Button filled={true} loading={isPending} type={ButtonType.submit} onClick={clickButton}>
                    {__translate('insertMediaBlock.button')}
                </Button>
            </BaseModalFooter>
        </BaseModal>
    );
};
