import { VideofeedConfig, VideofeedConfigService } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData } from '@common/defaults';

export const fetchVideofeedConfig = async (
    contextData: ContextData,
    isClientSide: boolean,
): Promise<VideofeedConfig | undefined> => {
    const { data } = await ApiService({
        contextData,
        isClientSide,
        service: VideofeedConfigService,
    }).getVideofeedConfigByDomainId({
        path: {
            contextID: contextData.context.id,
        },
    });

    return data;
};
