import type { EditorConfig, ElementFormatType, LexicalEditor, NodeKey } from 'lexical';

import { CustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { SerializedCustomNode } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode/CustomNode';
import { BaseModalProvider } from '@admin/organisms/BaseModal';
import { ArticleBlockType, MatchListBlockPatch } from '@common/clients/api';

import { MatchBlockArticleBlock } from '.';

export const createMatchBlockNode = (data: MatchListBlockPatch): MatchBlockNode => new MatchBlockNode(data);

export class MatchBlockNode extends CustomNode<MatchListBlockPatch, MatchBlockNode>(createMatchBlockNode) {
    static override __type = ArticleBlockType.MATCH_LIST_BLOCK;

    constructor(data: MatchListBlockPatch, format?: ElementFormatType, key?: NodeKey) {
        super(data, format, key);
    }

    static JSONtoSerializedNode(block: MatchListBlockPatch): SerializedCustomNode<MatchListBlockPatch> {
        return {
            type: ArticleBlockType.MATCH_LIST_BLOCK,
            data: {
                showCompact: block.showCompact,
                showDate: block.showDate,
                matches: block.matches || [],
                type: ArticleBlockType.MATCH_LIST_BLOCK,
            },
            version: 1,
            format: 'left',
        };
    }

    static override clone(node: MatchBlockNode): MatchBlockNode {
        return new MatchBlockNode(node.__data, node.__format, node.__key);
    }

    handleOnChange(editor: LexicalEditor) {
        return (data: MatchListBlockPatch) => {
            editor.update(() => {
                const writable: MatchBlockNode = this.getWritable();

                writable.__data.matches = data.matches;
            });
        };
    }

    override decorate(editor: LexicalEditor, _config: EditorConfig) {
        return (
            <BaseModalProvider>
                <MatchBlockArticleBlock
                    {...this.__data}
                    onChange={this.handleOnChange(editor)}
                    onDelete={this.handleOnDelete(editor)}
                />
            </BaseModalProvider>
        );
    }
}
