import { useEffect, useState } from 'react';

import { TeamVsTeam } from '@admin/atoms/TeamVsTeam';
import { ArticleBlock } from '@admin/molecules/RichEditor/Editor/atoms/ArticleBlock';
import { getMatch } from '@admin/molecules/RichEditor/Editor/helpers';
import { ModalActions, useModalContext } from '@admin/organisms/BaseModal';
import { ThemeOverride } from '@common/atoms/ThemeOverride';
import { ArticleBlockType, Match, MatchDetailSectionBlockPatch } from '@common/clients/api';
import { ThemeID } from '@common/types/ThemeID';
import { useContextData } from '@common/useContextData';

import { MatchDetailSectionModal } from '.';

import styles from './MatchDetailSectionArticleBlock.module.scss';

interface Props extends MatchDetailSectionBlockPatch {
    onChange: (data: MatchDetailSectionBlockPatch) => void;
    onDelete: () => void;
}

export const MatchDetailSectionArticleBlock = ({ onChange, onDelete, matchID, showOdds, type }: Props) => {
    const { openModal } = useModalContext(ArticleBlockType.MATCH_DETAIL_SECTION_BLOCK);
    const contextData = useContextData();
    const [match, setMatch] = useState<Match | null>();

    useEffect(() => {
        if (!matchID) return;
        getMatch({ contextData, matchID }).then(setMatch);
    }, [matchID, contextData]);

    if (!match) {
        return null;
    }

    return (
        <ThemeOverride themeID={ThemeID.ADMIN} className={styles.MatchDetailSectionArticleBlock}>
            <ArticleBlock
                onClick={openModal}
                onDelete={onDelete}
                articleBlockType={ArticleBlockType.MATCH_LIST_BLOCK}
            >
                <TeamVsTeam
                    className={styles.teamVsTeam}
                    kickOff={match.kickOff}
                    awayTeamName={match.away?.name}
                    homeTeamName={match.home?.name}
                />
            </ArticleBlock>
            <MatchDetailSectionModal
                formData={{ matchID, showOdds, type }}
                onSubmit={onChange}
                action={ModalActions.update}
            />
        </ThemeOverride>
    );
};
