import { Iframe } from '@web/atoms/Iframe';

export const YoutubeVideo = ({ videoID, isPortrait }: { videoID: string; isPortrait: boolean }) => {
    const embedVideoUrl = `https://www.youtube.com/embed/${videoID}`;

    return (
        <Iframe
            src={embedVideoUrl}
            allowFullScreen
            frameBorder={0}
            height={'100%'}
            isPortrait={isPortrait}
            data-testid="youtube-video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
    );
};
