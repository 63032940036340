import styles from './ProgressBar.module.scss';

export interface Props {
    loaded: number;
}

export const ProgressBar = ({ loaded }: Props) => {
    return (
        <div className={styles.ProgressBar}>
            <div
                dir="rtl"
                className={loaded < 100 ? styles.uploading : styles['done-uploading']}
                style={{ width: `${loaded}%` }}
            >
                <span>{`${loaded}%`}</span>
            </div>
        </div>
    );
};
