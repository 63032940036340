import { useEffect, useState } from 'react';

import { InputElementProps } from '@admin/molecules/InputElement/InputElement';
import { SearchBox } from '@admin/molecules/SearchBox';
import { Icon } from '@common/atoms/Icon';

import styles from './AutoComplete.module.scss';

export interface dataSourceEnvelope {
    text: string | number;
    element: React.ReactElement;
}

export interface Props extends InputElementProps {
    selectedItems: any;
    isMultiSelect: boolean;
    inputProps: any;
    minimumCharsCount: number;
    dataSource: ((searchText: string) => Promise<(dataSourceEnvelope | string)[]>) | undefined;
    onChange?: (value: unknown) => void;
}

const parsValueFromProps = (text: any): (dataSourceEnvelope | string)[] => {
    let parsedObject: any = {};

    try {
        parsedObject = JSON.parse(text);
    } catch {
        return [];
    }

    if (Array.isArray(parsedObject)) {
        return parsedObject;
    } else {
        return [
            {
                text: parsedObject.id || '',
                element: <p>{parsedObject.text || ''}</p>,
            },
        ];
    }
};

export const AutoComplete = ({
    dataSource,
    inputProps,
    isMultiSelect,
    minimumCharsCount,
    selectedItems,
    onChange,
}: Props) => {
    const [searchText, setSearchText] = useState<string>();
    const [itemsToShow, setItemsToShow] = useState<(dataSourceEnvelope | string)[]>([]);
    const [activeItems, setActiveItems] = useState<(dataSourceEnvelope | string)[]>(
        parsValueFromProps(selectedItems),
    );

    const fetchFromDataSource = async (text: string): Promise<void> => {
        let _items: (dataSourceEnvelope | string)[] = [];
        if (text.length >= minimumCharsCount) {
            const idList = activeItems.map((x) => {
                return typeof x === 'string' ? x : x.text;
            });

            if (dataSource && text) {
                _items = (await dataSource(text)).filter((item: dataSourceEnvelope | string) => {
                    const id: string | number = typeof item === 'string' ? item : item.text;
                    return !idList.includes(id);
                });
            }
        }

        setSearchText(text);
        setItemsToShow(_items);
    };

    const handleSearchBoxSubmit = (text: string) => {
        fetchFromDataSource(text);
    };

    const parseSelectedItems = () => {
        const ids = activeItems.map((item) => (typeof item === 'string' ? item : item.text));
        if (isMultiSelect) {
            return JSON.stringify(ids);
        } else {
            return ids.pop() || '';
        }
    };

    const handleItemIsChosen = (item: string | dataSourceEnvelope) => {
        if (!activeItems.includes(item)) {
            const _itemsSelected: (string | dataSourceEnvelope)[] = isMultiSelect
                ? activeItems.concat([item])
                : [item];

            const _itemsToShow = itemsToShow.filter((item: dataSourceEnvelope | string) => {
                return !_itemsSelected.includes(item);
            });

            setActiveItems(_itemsSelected);
            setItemsToShow(_itemsToShow);
            setSearchText('');
        }
    };

    const handleRemoveItem = (item: string | dataSourceEnvelope) => {
        if (activeItems) {
            const id: string | number = typeof item === 'string' ? item : item.text;

            const _items: (string | dataSourceEnvelope)[] = [...activeItems].filter((i) => {
                return typeof i === 'string' ? i !== id : i.text !== id;
            });

            setActiveItems(_items);

            // clear input if not isMultiSelect
            if (!isMultiSelect && _items.length === 0) {
                setSearchText('');
            }
        }
    };

    const getItemDetails = (item: string | dataSourceEnvelope, isHeader: boolean): React.ReactElement => {
        let itemName: string | number;
        let itemContent: React.ReactElement;

        if (typeof item === 'string') {
            itemName = item;
            itemContent = <p>{item}</p>;
        } else {
            itemName = item.text;
            itemContent = item.element;
        }

        if (isHeader) {
            return (
                <li key={itemName}>
                    <span className={styles['icon-container']}>
                        <Icon.xCircle onClick={() => handleRemoveItem(item)} />
                    </span>
                    <aside>{itemContent}</aside>
                </li>
            );
        } else {
            return (
                <li key={itemName} onClick={() => handleItemIsChosen(item)}>
                    <aside>{itemContent}</aside>
                </li>
            );
        }
    };

    useEffect(() => {
        if (searchText) {
            fetchFromDataSource(searchText);
        } else {
            setSearchText('');
            setItemsToShow([]);
        }
        // only run if searchText has changed
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setActiveItems(parsValueFromProps(selectedItems));
    }, [selectedItems]);

    useEffect(() => {
        if (onChange) onChange(activeItems);
    }, [onChange, activeItems]);

    const showFullComponent = (!isMultiSelect && activeItems.length === 0) || isMultiSelect;

    return (
        <div className={styles.AutoComplete}>
            {activeItems.length ? (
                <ul className={styles.header}>
                    {activeItems.map((item: dataSourceEnvelope | string) => {
                        return getItemDetails(item, true);
                    })}
                </ul>
            ) : null}

            {showFullComponent ? (
                <>
                    <div className={'stretch'}>
                        <SearchBox currentSearch={searchText || ''} onSubmit={handleSearchBoxSubmit} />
                    </div>

                    {itemsToShow.length ? (
                        <div className={styles.options}>
                            <ul>
                                {itemsToShow.map((item: dataSourceEnvelope | string) => {
                                    return getItemDetails(item, false);
                                })}
                            </ul>
                        </div>
                    ) : null}
                </>
            ) : null}
            <input
                {...inputProps}
                className="AutoComplete__hidden-input"
                key={inputProps.key || inputProps.name}
                type="hidden"
                value={parseSelectedItems()}
            />
        </div>
    );
};
